import { reactive, ref, onMounted } from 'vue';
import TigerGame from '../components/TigerGame/index.vue';
import showLuck from '../LuckyDrawResults/index.js';
import useFreeWithdraw from '@/store/modules/freeWithdraw.js';
import useAppStore from '@/store/modules/app';
import gamePopup from './index.js';
import { getMaxZIndex } from '@/utils/index';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    /** ***引入相关包start*****/
    const freeWithdrawStore = useFreeWithdraw();
    const appStore = useAppStore();
    const show = ref(false); // 弹出框是否显示

    const hasChangeBg = ref(false); //修改背景图片

    expose({
      show
    });
    const proxyData = reactive({
      remainCount: 1,
      // 剩余次数
      loading: false
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    // 关闭弹出框
    const unMountComponent = () => {
      gamePopup({
        type: 'unmount'
      });
    };

    /**
     * 游戏动画执行结束回调
     */
    const handleTigerGameEnd = e => {
      console.log('游戏动画执行结束', e);
      proxyData.remainCount--;
      setTimeout(() => {
        showLuck({
          props: {
            type: e.prizeAmount !== 0,
            money: e.prizeAmount
          }
        });
      }, 500);
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(() => {
      freeWithdrawStore.fetchWithdrawalRecords({
        pageSize: 60
      });
    });
    /** ***生命周期end*****/

    const __returned__ = {
      freeWithdrawStore,
      appStore,
      show,
      hasChangeBg,
      proxyData,
      unMountComponent,
      handleTigerGameEnd,
      reactive,
      ref,
      onMounted,
      TigerGame,
      showLuck,
      useFreeWithdraw,
      useAppStore,
      gamePopup,
      getMaxZIndex
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};