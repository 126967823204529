import request from '@/utils/request'

/**
 * 转盘抽奖
 * @returns
 */
export async function isCheckTurnApi(data) {
  return await request({
    url: '/kwai/app/roulette/activity/turn',
    method: 'post',
    data,
  })
}
/**
 * 已派发奖金
 * @returns
 */
export async function getPoolApi(params) {
  return await request({
    url: '/kwai/app/roulette/activity/getPool',
    method: 'get',
    params,
  })
}
/**
 * 是否开启转盘抽奖
 * @returns
 */
export async function isOpenTurnApi(params) {
  return await request({
    url: '/kwai/app/roulette/activity/isOpen',
    method: 'get',
    params,
  })
}
/**
 * 获取转盘抽奖奖项
 * @returns
 */
export async function getConfigListApi(params) {
  return await request({
    url: '/kwai/app/roulette/activity/getConfigList',
    method: 'get',
    params,
  })
}
/**
 * 获取转盘抽奖次数
 * @returns
 */
export async function getTurnCountApi(params) {
  return await request({
    url: '/kwai/app/roulette/activity/getTurnCount',
    method: 'get',
    params,
  })
}

/**
 * 获取赠送抽奖次数&进度信息
 * @returns
 */
export async function getPeriodSumApi(params) {
  return await request({
    url: '/kwai/app/roulette/activity/getPeriodSum',
    method: 'get',
    params,
  })
}

/**
 * 获取全部抽奖记录
 * @returns
 */
export async function getTotalListApi(params) {
  return await request({
    url: '/kwai/app/roulette/activity/list',
    method: 'get',
    params,
  })
}

/**
 * 获取我的抽奖记录
 * @returns
 */
export async function getCustomerListApi(params) {
  return await request({
    url: '/kwai/app/roulette/activity/customer/list',
    method: 'get',
    params,
  })
}
