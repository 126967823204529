import Bubbles from './bubbles.vue';
export default {
  props: {
    // 是否选中
    active: {
      type: Boolean,
      default: false
    },
    text: {
      type: [Number, String],
      default: ""
    },
    size: {
      type: String,
      default: "normal" //large大号，normal正常，small小号，mini小小号,
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const __returned__ = {
      Bubbles
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};