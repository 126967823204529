import axios from 'axios'
import { defineStore } from 'pinia'
import vueI18n from '@/i18n/index'
const languageAjax = JSON.parse(process.env.VUE_APP_LANGUAGE_OBJ)
const useSystemLanguage = defineStore('systemLanguage', {
  state: () => ({
    languageObject: {}, // 国际化 后台返回的
  }),
  actions: {
    // 国际化请求
    async languageListFun() {
      try {
        axios
          .post(languageAjax.url, { appCode: languageAjax.appCode })
          .then((response) => {
            if (response.data.code !== 200) {
              alert('i8n error;Internationalization error')
              return
            }
            this.languageObject = response.data.data
            for (const key in this.languageObject) {
              vueI18n.global.setLocaleMessage(key, Object.assign(this.languageObject[key]))
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } catch (error) {
        console.log(error)
      }
    },
  },
})

export default useSystemLanguage
