import { ref, onMounted } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import payMethods from './index.js';
import QRCode from 'qrcode';
import useAppStore from '@/store/modules/app.js';
import { copyText } from '@/utils/index';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    amount: {
      type: Number,
      default: null
    },
    imageData: {
      type: String,
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    const props = __props;
    /** ***引入相关包start*****/

    const appStore = useAppStore();
    const show = ref(false);
    const imgUrl = ref(null);
    expose({
      show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    //取消
    const close = () => {
      show.value = false;
      unMountComponent();
    };

    // 销毁组件
    const unMountComponent = () => {
      payMethods({
        type: 'unmount'
      });
    };
    const generateQR = async text => {
      try {
        imgUrl.value = await QRCode.toDataURL(text);
      } catch (err) {
        console.error(err);
      }
    };

    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(async () => {
      props.imageData && (await generateQR(props.imageData));
    });
    /** ***生命周期end*****/

    const __returned__ = {
      props,
      appStore,
      show,
      imgUrl,
      close,
      unMountComponent,
      generateQR,
      ref,
      onMounted,
      MyPopup,
      payMethods,
      QRCode,
      useAppStore,
      copyText
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};