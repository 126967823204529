import useCacheData from '@/store/modules/cacheData'
const selectedActivity = () => {
  // 获取缓存数据
  const cacheData = useCacheData()
  // type  活动类型

  const handleEventActivity = (type) => {
    return cacheData.bannerList.filter((item) => {
      return item.activityType === type
    })
  }

  return {
    handleEventActivity,
  }
}

export default selectedActivity
