import request from '@/utils/request'

/**
 * 注册是否需要验证码
 * @returns
 */
export async function isCheckCodeApi(data) {
  return await request({
    url: '/kwai/api/custom/isCheckCode',
    method: 'post',
    data,
  })
}
/**
 * 注册验证码
 * @param customName	用户名
 * @returns
 */
export async function sendRegisterApi(data) {
  return await request({
    url: '/kwai/api/code/register/send',
    method: 'post',
    data,
  })
}
/**
 * 注册
 * @param channel	渠道
 * @param code	验证码，没有传空
 * @param confirmPwd	传空
 * @param inviteCode	邀请码，没有传空
 * @param password	密码
 * @param customName	用户名
 * @returns
 */
export async function registerApi(data) {
  return await request({
    url: '/kwai/api/custom/register',
    method: 'post',
    data,
  })
}
/**
 * 注册奖励，手动领取
 * @returns
 */
export async function receiveRegisterBonus(data) {
  return await request({
    url: '/kwai/app/custom/closeRegisterPopBox',
    method: 'post',
    data,
  })
}
/**
 * 登录
 * @param customName 用户名
 * @param password 密码
 * @returns
 */
export async function loginApi(data) {
  return await request({
    url: '/auth/kwai/login',
    method: 'post',
    data,
  })
}
/**
 * 忘记密码发送验证码
 * @param customName 用户名
 * @returns
 */
export async function sendApi(data) {
  return await request({
    url: '/kwai/api/code/forget/send',
    method: 'post',
    data,
  })
}
/**
 * 忘记密码
 * @param customName 用户名
 * @param password 密码
 * @param code	验证码，没有传空
 * @returns
 */
export async function forgetpwdApi(data) {
  return await request({
    url: '/kwai/api/custom/forgetpwd',
    method: 'post',
    data,
  })
}
/**
 * 用户信息
 * @returns
 */
export async function customInfoApi(data) {
  return await request({
    url: '/kwai/app/custom/customInfo',
    method: 'post',
    data,
  })
}
/**
 * 用户余额
 * @returns
 */
export async function customBalanceApi(data) {
  return await request({
    url: '/kwai/app/custom/customBalance',
    method: 'post',
    data,
  })
}
/**
 * 返利详情
 * @returns
 */
export async function rebateDetailApi(params) {
  return await request({
    url: '/kwai/app/bet/rebate/detail',
    method: 'get',
    params,
  })
}
/**
 * 接收返利
 * @returns
 */
export async function receiveRebateApi(data) {
  return await request({
    url: '/kwai/app/bet/rebate/receive',
    method: 'post',
    data,
  })
}
/**
 * vip等级
 * @returns
 */
export async function levelUpApi(data) {
  return await request({
    url: '/kwai/app/vip/levelUp',
    method: 'post',
    data,
  })
}

/**
 * 绑定指纹
 * @returns
 */
export async function bindFingerprint(data) {
  return await request({
    url: '/kwai/app/custom/bindFingerprint',
    method: 'post',
    data,
  })
}

/**
 * google登录
 * @returns
 */
export async function googleLoginApi(data) {
  return await request({
    url: '/auth/api/kwai/googleLogin',
    method: 'post',
    data,
  })
}
/**
 * facebook登录
 * @returns
 */
export async function facebookLoginApi(data) {
  return await request({
    url: '/auth/api/kwai/facebookLogin',
    method: 'post',
    data,
  })
}

/**
 * 初始化密码
 * @returns
 */
export async function initPasswordApi(data) {
  return await request({
    url: '/kwai/app/custom/initPwd',
    method: 'post',
    data,
  })
}
