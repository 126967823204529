import MyPopup from '@/components/MyPopup/index.vue';
import { getMaxZIndex } from '@/utils';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['agreementShowFun'],
  setup(__props, {
    expose,
    emit
  }) {
    expose();
    const {
      t
    } = useI18n();
    const unMountComponent = () => {
      emit('agreementShowFun');
    };
    const __returned__ = {
      t,
      emit,
      unMountComponent,
      MyPopup,
      getMaxZIndex,
      useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};