import MyPopup from '@/components/MyPopup';
import { ref } from 'vue';
import failView from './components/fail.vue';
import successView from './components/success.vue';
import useAppStore from '@/store/modules/app';
import closeDialog from './index.js';
import TigerGamePopup from '../TigerGamePopup/index.js';
import useFreeWithdraw from '@/store/modules/freeWithdraw.js';
import { getMaxZIndex } from '@/utils/index';
import router from '@/router';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    callback: {
      type: Function,
      // 成功回调
      default: null
    },
    remove: {
      type: Function,
      // 传入移除节点方法,这里是createApp中的方法
      default: null
    },
    type: {
      type: Boolean,
      default: true
    },
    money: {
      type: Number,
      default: 0
    },
    closeCallBackForParents: {
      type: Function,
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    const props = __props;
    /** ***引入相关包start*****/
    const freeWithdraw = useFreeWithdraw();
    const appStore = useAppStore();
    const showFail = ref(!props.type);
    const showSuccess = ref(props.type);
    const showDialog = ref(true); // 弹窗

    expose({
      showDialog
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    const handleOk = () => {
      //关闭自己
      closeDialog({
        type: 'unmount'
      });
      //关闭参与抽奖
      TigerGamePopup({
        type: 'unmount'
      });
      props.callback && props.callback();

      //更新活动状态
      // freeWithdraw.freeWithdrawActivityExecute()

      if (appStore.device === 'mobile') {
        appStore.toggleSideBar('close');
      }

      //没有次数且完成
      // if (!freeWithdraw.turnCount) router.back()
    };
    /** ***函数 end*****/

    const __returned__ = {
      freeWithdraw,
      props,
      appStore,
      showFail,
      showSuccess,
      showDialog,
      handleOk,
      MyPopup,
      ref,
      failView,
      successView,
      useAppStore,
      closeDialog,
      TigerGamePopup,
      useFreeWithdraw,
      getMaxZIndex,
      router
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};