import { defineStore } from 'pinia'
// 轮播图
import { activityList } from '@/api/activity'
// 用户信息接口
import useUserInfoStore from './userInfo'
//系统参数
import useAppStore from './app.js'
// 侧边栏接口
import { getSides } from '@/api/sideBar/index'
// 游戏列表
import { tabGameList, hotList, historyList, favoriteList, gameList, reqTopList } from '@/api/game'
// 热门游戏图标
const iconHot = require('../../assets/images/home/icon_dtfl_rm_1.png')
// 最近
const iconHistory = require('../../assets/images/home/icon_dtfl_zj_1.png')
// 收藏
const iconFavorite = require('../../assets/images/home/icon_dtfl_sc_1.png')
import usePromptStore from '@/store/modules/beforeinstallprompt'

const useCacheData = defineStore('useCacheData', {
  state: () => ({
    alertConfig: [], // 顶部和小喇叭数据
    SideBarList: [], // 侧边栏数据
    bannerList: [], // 轮播图数据
    pageSize: useAppStore.device === 'mobile' ? 9 : 12, // 请求数据数量
    gameTabsList: [], // 游戏数据
    gameIndex: 0, // 当前游戏分类下标，游戏首页、侧边栏
    gameLocationIndex: 0, // 侧边栏点击的时候定位到具体位置
  }),
  actions: {
    // 小喇叭数据
    async reqTopListInit() {
      const res = await reqTopList({})
      if (res.data && res.data.length) {
        const list = []
        res.data.forEach((item) => {
          if (item.langList && item.langList.length) {
            item.langList.forEach((obj) => {
              if (obj.value === process.env.VUE_APP_LANGUAGE) {
                item.langList = obj
              }
            })
          }
          list.push(item)
        })
        this.alertConfig = list
        // 过滤出apk 多站点和不是多站点获取数据不一样
        usePromptStore().AndroidLinkFun()
      }
    },
    // 获取首页banner图接口
    async getBanner() {
      try {
        if (this.bannerList.length) {
          return
        }
        const res = await activityList({ pageNum: 1 })
        this.bannerList = res.data.pageData || []
      } catch (error) {
        console.log(error)
      }
    },
    // 侧边栏接口
    async fetchSideBarData() {
      try {
        if (this.SideBarList.length) {
          return
        }
        const res = await getSides()
        this.SideBarList = res.data
      } catch (error) {
        console.log(error)
      }
    },
    // 历史玩的游戏记录
    async historyInit() {
      try {
        const params = { pageSize: this.pageSize, pageNum: 1 }
        const res = await historyList(params)
        let list = {}
        // 最近玩游戏分类
        const historyGame = res.data.pageData || []
        list={
          isAll: false, // 是否展示更多
          icon: iconHistory, // 分类图标
          id: 'Recente',
          loading: false, // 局部模块加载loading
          gameCount: res.data.total || 0, // 当前这个分类游戏数量
          tabName: 'Recente', // 分类名称
          type: 'history', // 分类type
          list: historyGame, // 游戏
          pageNum: res.data.pageNum || 1,
        }
        // 先查找之前列表是否存在，不存在就直接push否则就是替换
        let targetIndex = this.gameTabsList.findIndex((item) => item.id === 'Recente')
        if (targetIndex !== -1) {
          this.gameTabsList[targetIndex] = list
        } else {
          this.gameTabsList.push(list)
        }
      } catch (error) {
        console.log(error)
      }
    },
    //最喜欢的
    async favoriteInit() {
      try {
        const params = { pageSize: this.pageSize, pageNum: 1 }
        const res = await favoriteList(params)
        let list = {}
        // 我的收藏
        const favoriteGame = res.data.pageData
        list={
          isAll: false, // 是否展示更多
          icon: iconFavorite, // 分类图标
          id: 'Favoritos',
          loading: false, // 局部模块加载loading
          gameCount: res.data.total || 0, // 当前这个分类游戏数量
          tabName: 'Favoritos', // 分类名称
          type: 'favorite', // 分类type
          list: favoriteGame || [], // 游戏
          pageNum: res.data.pageNum || 1,
        }
        // 先查找之前列表是否存在，不存在就直接push否则就是替换
        let targetIndex = this.gameTabsList.findIndex((item) => item.id === 'Favoritos')
        if (targetIndex !== -1) {
          this.gameTabsList[targetIndex] = list
        } else {
          this.gameTabsList.push(list)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 游戏分类和游戏集合初始化+热门游戏+
    async tabGameListData() {
      if (this.gameTabsList.length) {
        return
      }
      const params = { pageSize: this.pageSize, pageNum: 1 }
      Promise.all([hotList(params), tabGameList(params)])
        .then((res) => {
          // 组合数据集合 热门、、系统分类 ，type描述  hot
          let list = []

          // 热门游戏分类
          if (res[0].data.pageData && res[0].data.pageData.length) {
            const hotGame = res[0].data.pageData
            list.push({
              isAll: false, // 是否展示更多
              icon: iconHot, // 分类图标
              id: 'Popular',
              loading: false, // 局部模块加载loading
              gameCount: res[0].data.total || 0, // 当前这个分类游戏数量
              tabName: 'Popular', // 分类名称
              type: 'hot', // 分类type
              list: hotGame, // 游戏
              pageNum: res[0].data.pageNum || 1,
            })
          }

          // 正常游戏分类
          res[1].data.forEach((item) => {
            // 只有有游戏的才可以放到页面显示
            if (item.list && item.list.length) {
              let gameInfo = item.list
              list.push({
                isAll: true, // 是否展示更多
                icon: item.icon, // 分类图标
                id: item.id,
                loading: false, // 局部模块加载loading
                gameCount: item.gameCount, // 当前这个分类游戏数量
                tabName: item.tabName, // 分类名称
                type: item.type, // 分类type
                list: gameInfo, // 游戏
                pageNum: 1,
              })
            }
          })

          this.gameTabsList = list
          if (useUserInfoStore().token) {
            // 最近玩的
            this.historyInit()
            // 最喜欢的
            this.favoriteInit()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 数据分页   // 组合数据集合 热门、最近、收藏 、系统分类 ，type描述  hot、history、favorite
    async loadMoreFun(type, index, pageNum, tabId) {
      // 执行loading
      this.gameTabsList[index].loading = true
      try {
        if (type === 'hot') {
          const resHot = await hotList({
            pageNum: ++pageNum,
            pageSize: this.pageSize,
          })
          // 热门游戏分类
          if (resHot.data.pageData && resHot.data.pageData.length) {
            const hotGame = resHot.data.pageData
            this.gameTabsList[index] = {
              isAll: false, // 是否展示更多
              icon: iconHot, // 分类图标
              id: 'Popular',
              gameCount: resHot.data.total || 0, // 当前这个分类游戏数量
              tabName: 'Popular', // 分类名称
              type: 'hot', // 分类type
              list: [...this.gameTabsList[index].list, ...hotGame], // 游戏
              pageNum: resHot.data.pageNum || 1,
            }
          }
        } else if (type === 'history') {
          const resHistory = await historyList({
            pageNum: ++pageNum,
            pageSize: this.pageSize,
          })
          // 最近玩游戏分类
          if (resHistory.data.pageData && resHistory.data.pageData.length) {
            const historyGame = resHistory.data.pageData
            this.gameTabsList[index] = {
              isAll: false, // 是否展示更多
              icon: iconHistory, // 分类图标
              id: 'Recente',
              gameCount: resHistory.data.total || 0, // 当前这个分类游戏数量
              tabName: 'Recente', // 分类名称
              type: 'history', // 分类type
              list: [...this.gameTabsList[index].list, ...historyGame], // 游戏
              pageNum: resHistory.data.pageNum || 1,
            }
          }
        } else if (type === 'favorite') {
          const resFavorite = await favoriteList({
            pageNum: ++pageNum,
            pageSize: this.pageSize,
          })
          // 我的收藏
          if (resFavorite.data.pageData && resFavorite.data.pageData.length) {
            const favoriteGame = resFavorite.data.pageData
            this.gameTabsList[index] = {
              isAll: false, // 是否展示更多
              icon: iconFavorite, // 分类图标
              id: 'Favoritos',
              gameCount: resFavorite.data.total || 0, // 当前这个分类游戏数量
              tabName: 'Favoritos', // 分类名称
              type: 'favorite', // 分类type
              list: [...this.gameTabsList[index].list, ...favoriteGame], // 游戏
              pageNum: resFavorite.data.pageNum || 1,
            }
          }
        } else {
          const resAll = await gameList({
            pageNum: ++pageNum,
            pageSize: this.pageSize,
            tabId,
          })
          // 正常游戏分类只有一个

          if (resAll.data.pageData && resAll.data.pageData.length) {
            const allPageData = resAll.data.pageData
            this.gameTabsList[index].gameCount = resAll.data.total
            this.gameTabsList[index].pageNum = resAll.data.pageNum
            this.gameTabsList[index].list = [...this.gameTabsList[index].list, ...allPageData]
          }
        }
        this.gameTabsList[index].loading = false
      } catch (error) {
        console.log(error)
        this.gameTabsList[index].loading = false
      }
    },
    // 切换分类实现电梯楼层
    changeGameIndex(index) {
      this.gameIndex = index
    },
    // 侧边栏切换分类实现电梯楼层
    changeGameLocationIndex(index) {
      this.gameLocationIndex = index
      this.gameIndex = index
    },
  },
})

export default useCacheData
