import request from '@/utils/request'
// 活动列表接口
export const activityList = (data) =>
  request({
    url: '/kwai/api/activity/list',
    method: 'post',
    data,
  })

// 活动详情
export const getActivityDetail = (params) => {
  return request({
    url: `/kwai/api/activity/${params.id}`,
    method: 'get',
  })
}
