import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-607419e4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
const _hoisted_2 = {
  class: "main__content"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "main__details"
};
const _hoisted_5 = {
  class: "main__details__item"
};
const _hoisted_6 = {
  class: "main__details__item"
};
const _hoisted_7 = {
  class: "main__details__item"
};
const _hoisted_8 = {
  class: "main__details__item"
};
const _hoisted_9 = {
  class: "order-number"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["MyPopup"], {
    modelValue: $setup.show,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.show = $event),
    headerText: _ctx.$t('recharge.detailsPage.pageTitle'),
    onClose: $setup.unMountComponent,
    onClickOverlay: $setup.unMountComponent,
    class: "recharge-records-details-popup my-popup--pc"
  }, {
    default: _withCtx(() => [_createElementVNode("main", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
      src: require(`./images/${$props.data.state}.png`)
    }, null, 8 /* PROPS */, _hoisted_3), _createElementVNode("span", {
      style: _normalizeStyle({
        color: $props.data.color
      })
    }, _toDisplayString($props.data.stateText), 5 /* TEXT, STYLE */), _createElementVNode("strong", null, _toDisplayString(_ctx.$t('systemSetting.moneySymbol')) + " " + _toDisplayString($setup.moneyFormat($props.data.amount)), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('recharge.detailsPage.field1')), 1 /* TEXT */), _createElementVNode("strong", null, _toDisplayString($props.data.typeText), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('recharge.detailsPage.field2')), 1 /* TEXT */), _createElementVNode("strong", null, _toDisplayString(_ctx.$t('recharge.paymentModel')), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('recharge.detailsPage.field3')), 1 /* TEXT */), _createElementVNode("strong", null, _toDisplayString($setup.formatDateTimer($props.data.date, 'DD/MM/YYYY HH:mm:ss')), 1 /* TEXT */)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('recharge.detailsPage.field4')), 1 /* TEXT */), _createElementVNode("div", _hoisted_9, [_createElementVNode("strong", null, _toDisplayString($props.data.orderNo), 1 /* TEXT */), _createElementVNode("i", {
      class: "iconfont icon-Frame-1",
      onClick: _cache[0] || (_cache[0] = $event => $setup.copyText($props.data.orderNo))
    })])])])])]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "headerText"]);
}