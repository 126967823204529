import { defineStore } from 'pinia'
import { getCopywriterList, copywriterGetCopyWriter, skinGetConfig } from '@/api/app/index.js'
import usePromptStore from '@/store/modules/beforeinstallprompt'
import TGLottery from '@/hooks/getTGLottery/index'
const useCopyWriter = defineStore('copywriteradd', {
  state: () => ({
    language: process.env.VUE_APP_LANGUAGE, // 国际化和app.js 保持一致
    site: process.env.VUE_APP_SITE, // yes 多站点  no  普通站点
    copyWriterMap: {}, // 国际化 后台返回的
    appInfo: {}, // 应用信息
  }),
  actions: {
    // 获取应用信息
    async appInfoFun() {
      try {
        const res = await skinGetConfig({})
        if (Object.keys(res.data).length) {
          res.data.configData = JSON.parse(res.data.configData)
          res.data.logoList = res.data.logoList || []
          const logoList = {}
          res.data.logoList.forEach((element) => {
            logoList[element.locationType] = element
          })
          res.data.logoList = logoList
          this.appInfo = res.data
          // 设置网站ico图标
          if (this.appInfo?.logoList[4]?.url) {
            const favicon = document.querySelector('link[rel="icon"]')
            const timestamp = new Date().getTime() // 获取当前时间戳
            favicon.href = `${this.appInfo?.logoList[4]?.url}?t=${timestamp}`
          }
          // 设置网页项目名称
          document.title = res.data?.configData?.name
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 国际化请求
    async getCopywriterListFun() {
      // 多站模式文案
      if (this.site === 'yes') {
        try {
          const res = await copywriterGetCopyWriter({})
          const obj = res.data ? res.data : {}
          this.copyWriterMap = obj
          // 过滤出apk 多站点和不是多站点获取数据不一样
          usePromptStore().AndroidLinkFun()
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const res = await getCopywriterList({})
          this.copyWriterMap = res.data
        } catch (error) {
          console.log(error)
        }
      }

      //tg code
      const tg_code = this.getQueryString('tg_code')
      if (tg_code) {
        TGLottery().getTGLottery({ props: { tg_code } })
      }
    },
    getQueryString(name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substring(1).match(reg)
      if (r != null) return decodeURI(r[2])
      return null
    },
  },
})

export default useCopyWriter
