import "ant-design-vue/es/message/style";
import _message from "ant-design-vue/es/message";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import { ref, onMounted, getCurrentInstance } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import lotteryCode from './index.js';
import RenderCopywriting from '@/components/RenderCopywriting.vue';
import useCopyWriter from '@/store/modules/copywriter';
import useAppStore from '@/store/modules/app';
import useUserInfoStore from '@/store/modules/userInfo.js';
import router from '@/router';
import { receiveByTgCode } from '@/api/tgLottery/index.js';
import rechargePopup from '@/views/rechargeWithdraw/rechargePopup/index.js';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup(__props, {
    expose
  }) {
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const show = ref(false);
    const copyWriter = useCopyWriter();
    const appStore = useAppStore();
    // 用户信息
    const userInfo = useUserInfoStore();
    // 获取全局组件实例
    const {
      proxy
    } = getCurrentInstance();
    const code = ref(''); //兑换码
    const mobileValue = ref(null);
    const isLoading = ref(false);

    // notReceiveReason:
    // 1-vip等级不足
    // 2-无效的活动
    // 3-已达总限制
    // 4-已达今日限
    // 5-充值金额不足
    const notReceiveReasonBtn_Comp = ref('');
    const noReceiveReason = ref('');
    const activityId = ref('');
    const MESSAGEMAP = {
      1: t('popup.lotterycode.status1'),
      2: t('popup.lotterycode.status2'),
      3: t('popup.lotterycode.status3'),
      4: t('popup.lotterycode.status4')
    };
    const minAmount = ref(0);
    const timer1 = ref(0);
    const contentList = ref([]);
    expose({
      show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    const getTgLottery = async () => {
      if (!userInfo.token) {
        proxy.$showLogin({
          type: 'login'
        }).catch(() => {});
        return;
      }
      if (!code.value) return;
      if (new Date().getTime() - timer1.value < 300) return;
      timer1.value = new Date().getTime();
      // 获取领取信息, 可领取, 并且vip等级够, 则让领取, 否则弹窗上按钮让用户去升级vip
      // receiveStatus 领取状态，0:不可领取；1:可领取；2:已领取
      // 当不可领取的时候需要判断notReceiveReason 不可领取原因, 1: vip等级不足 的时候需要弹窗告诉用户, 其他原因不弹窗
      const res = await receiveByTgCode({
        tgCode: code.value
      });
      const {
        receiveStatus,
        notReceiveReason,
        id,
        minRechargeAmount,
        rewardAmount
      } = res.data;
      //领取成功
      if (receiveStatus === '2') {
        _message.success(t('popup.lotterycode.receive', [rewardAmount, t('systemSetting.moneySymbol')]), 2);
        userInfo.updateBalance();
        unMountComponent();
        return;
      }
      //不能领取原因
      if (['2', '3', '4'].includes(notReceiveReason)) {
        _message.error(MESSAGEMAP[notReceiveReason], 2);
        return;
      }
      if (receiveStatus === '1' || receiveStatus === '0' && ['1', '3', '4', '5'].includes(notReceiveReason)) {
        notReceiveReasonBtn_Comp.value = getMessage(notReceiveReason);
        noReceiveReason.value = notReceiveReason;
        activityId.value = id;
        minAmount.value = minRechargeAmount;
      }
    };

    //消息按钮
    const getMessage = index => {
      if (index === '1') {
        _message.error(MESSAGEMAP[index], 2);
        return t('popup.lotterycode.upVIP');
      }
      if (['2', '3', '4'].includes(index)) {
        return t('popup.lotterycode.tips');
      }
      if (index === '5') {
        return t('popup.lotterycode.recharge');
      }
      return t('popup.lotterycode.getIt');
    };
    const handleLeave = () => {
      // 跳转vip
      if (noReceiveReason.value === '1') {
        router.push({
          name: 'activity',
          query: {
            activeIndex: 1
          }
        });
        unMountComponent();
        return;
      }
      if (['2', '3', '4'].includes(noReceiveReason.value)) {
        unMountComponent();
        return;
      }
      if (noReceiveReason.value === '5') {
        rechargePopup();
        unMountComponent();
        return;
      }
    };
    const getQueryString = name => {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      const r = window.location.search.substring(1).match(reg);
      if (r != null) return decodeURI(r[2]);
      return null;
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(() => {
      const tg_code = getQueryString('tg_code');
      code.value = tg_code ? tg_code : '';
      //多站点
      if (copyWriter.site === 'yes') {
        var _copyWriter$copyWrite;
        contentList.value = (_copyWriter$copyWrite = copyWriter.copyWriterMap) === null || _copyWriter$copyWrite === void 0 ? void 0 : _copyWriter$copyWrite['lottery-code-info'].langData[appStore.language][0].text.split('\n');
      } else {
        var _copyWriter$copyWrite2;
        contentList.value = (_copyWriter$copyWrite2 = copyWriter.copyWriterMap) === null || _copyWriter$copyWrite2 === void 0 ? void 0 : _copyWriter$copyWrite2['lottery-code-info'][appStore.language].split('\n');
      }
    });
    // 销毁组件
    const unMountComponent = () => {
      code.value = null;
      lotteryCode({
        type: 'unmount'
      });
    };
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      show,
      copyWriter,
      appStore,
      userInfo,
      proxy,
      code,
      mobileValue,
      isLoading,
      notReceiveReasonBtn_Comp,
      noReceiveReason,
      activityId,
      MESSAGEMAP,
      minAmount,
      timer1,
      contentList,
      getTgLottery,
      getMessage,
      handleLeave,
      getQueryString,
      unMountComponent,
      ref,
      onMounted,
      getCurrentInstance,
      AntMessage: _message,
      MyPopup,
      lotteryCode,
      RenderCopywriting,
      useCopyWriter,
      useAppStore,
      useUserInfoStore,
      router,
      receiveByTgCode,
      rechargePopup,
      useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};