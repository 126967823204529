import { ref, watch } from 'vue';
import useAppStore from '@/store/modules/app.js';
import usePromptStore from '@/store/modules/beforeinstallprompt';
import useCopyWriter from '@/store/modules/copywriter';
import useSessionCache from '@/hooks/storage/sessionStorage';
import useLocalCache from '@/hooks/storage/localStorage';
export default {
  setup(__props, {
    expose
  }) {
    expose();
    const {
      sethasCloseDownload,
      gethasCloseDownload
    } = useSessionCache();
    const {
      setCloseDownload
    } = useLocalCache();
    // app应用
    const appStore = useAppStore();
    const promptStore = usePromptStore();
    const show = ref(appStore.hasCloseDownload);
    // 下载apk或者pwa安装
    const DownloadClick = () => {
      promptStore.down();
    };
    // 关闭导航栏
    const closeFun = () => {
      appStore.hasCloseDownload = true;
      sethasCloseDownload(true);
      setCloseDownload(true);
      setTimeout(() => {
        appStore.offsetTopFun();
      }, 20);
    };
    watch(() => appStore.hasCloseDownload, () => {
      show.value = gethasCloseDownload();
    });
    const __returned__ = {
      sethasCloseDownload,
      gethasCloseDownload,
      setCloseDownload,
      appStore,
      promptStore,
      show,
      DownloadClick,
      closeFun,
      ref,
      watch,
      useAppStore,
      usePromptStore,
      useCopyWriter,
      useSessionCache,
      useLocalCache
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};