import showFreeWithdrawSharePopup from '@/views/activity/events/details/components/FreeWithdraw/SharePopup/index.js';
import selectedActivity from '@/hooks/selectedActivity/index';
import router from '@/router';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    handleOk: {
      type: Function,
      // 成功回调
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    const toDetail = () => {
      props.handleOk();
      const list = selectedActivity().handleEventActivity('19');
      router.push({
        path: '/eventsDetail',
        query: {
          id: list[0].id,
          type: list[0].activityType
        }
      });
    };
    const toShare = () => {
      props.handleOk();
      showFreeWithdrawSharePopup();
    };
    /** ***函数 end*****/

    const __returned__ = {
      props,
      toDetail,
      toShare,
      showFreeWithdrawSharePopup,
      selectedActivity,
      router
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};