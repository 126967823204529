import request from '@/utils/request'

// 待领取列表
export const awaitingCollectionListApi = (params) => {
  return request({
    url: '/kwai/app/custom/reward/getUnReceiveList',
    method: 'get',
    params,
  })
}
// 获取用户总的可领取金额
export const awaitingCollectionTotalApi = (params) => {
  return request({
    url: '/kwai/app/custom/reward/getRewardAmount',
    method: 'get',
    params,
  })
}
// 领取奖励
export const awaitingCollectionApi = (data) => {
  return request({
    url: '/kwai/app/custom/reward/receive',
		method: 'post',
		data,
  })
}
// 待领取奖励总个数
export const awaitingCollectionCountApi = (params) => {
  return request({
    url: '/kwai/app/custom/reward/getUnReceiveCount',
		method: 'get',
		params,
  })
}
