// 免费提现活动
import { defineStore } from 'pinia'
import { showLoadingToast } from 'vant'
import {
  freeWithdrawActivityStatusApi,
  freeWithdrawAttendStatusApi,
  freeWithdrawTurnCountApi,
  freeWithdrawTurnApi,
  getActivityProgress,
  getWithdrawalRecords,
  freeWithdrawDetailRecordsApi,
} from '@/api/FreeWithdraw/index.js'
import showTigerGamePopup from '@/views/activity/events/details/components/FreeWithdraw/TigerGamePopup/index.js'
import WithdrawSuccessPop from '@/views/activity/events/details/components/FreeWithdraw/WithdrawSuccess/index'
import useUserInfoStore from '@/store/modules/userInfo.js'
import { getQueryString } from '@/utils'
import selectedActivity from '@/hooks/selectedActivity/index'
import router from '@/router'
const useFreeWithdraw = defineStore('freeWithdraw', {
  state: () => ({
    status: null, // 活动状态，0-代表活动开启；1-代表活动关闭
    attendStatus: null, // 1-可以参加；2-参加活动中；3-不能参加
    turnCount: null, // 剩余抽奖次数
    prizeAmount: null, // 中奖金额
    activityProgress: {}, // 个人参与的活动进度
    withdrawalRecords: [], // 获取所有用户提现成功记录
    withdrawDetailRecords: [], // 获取所有用户提现明细记录
    loginInProress: false, // 是否登录进行中，用来当用户从首次参与的页面点击开始调整到登录，登录完成以后要重新重载免费提现活动的首次参与弹窗
  }),

  actions: {
    // 设置登录进行中，用于登录注册成功返回要显示免费活动提现首次参与弹窗还是进行中弹窗
    setLoginInProress(data) {
      this.loginInProress = data
    },

    // 判断用户是否登录
    isLogin() {
      // 未登录的且活动开启状态下在弹出pdd
      if (!useUserInfoStore().token && this.status === '0') {
        showTigerGamePopup()
        this.loginInProress = false
        return 401
      } else {
        return 200
      }
    },
    // 获取活动的状态（活动入口是否开启） enterSideBar 用户是否是从侧边栏进入的
    async freeWithdrawActivityExecute(enterSideBar = false) {
      // 如果从侧边栏进来并且活动已经开启
      if (enterSideBar && this.status === '0') {
        // 用户未登录会弹窗免费提现活动首次抽奖弹窗
        if (this.isLogin() === 401) return

        // 如果已经登录并且从侧边栏进来要给个loading，因为请求接口后判断要弹那个弹窗
        let toast = null
        toast = showLoadingToast({
          forbidClick: true,
          duration: 0,
          loadingType: 'spinner',
        })
        await this.freeWithdrawAttendStatus(enterSideBar)
        if (toast) {
          toast.close()
          toast = null
        }
      } else {
        const activityStatus = await this.freeWithdrawActivityStatus()
        if ((activityStatus === '0' && this.isLogin() === 401) || activityStatus !== '0') return
        await this.freeWithdrawAttendStatus(enterSideBar)
      }
    },

    // 获取活动的状态（活动入口是否开启）
    async freeWithdrawActivityStatus() {
      try {
        const { data } = await freeWithdrawActivityStatusApi()
        // 活动状态，0-代表活动开启；1-代表活动关闭
        this.status = data
        return data
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    // 获取活动的参加状态
    async freeWithdrawAttendStatus(enterSideBar = false) {
      try {
        if (useUserInfoStore().token) {
          await this.freeWithdrawTurnCount()

          const { data } = await freeWithdrawAttendStatusApi()
          if (this.turnCount) {
            this.loginInProress = true
          }
          this.attendStatus = data
          // 状态为1表示用户是首次参与免费提现活动，要直接弹窗
          const type = getQueryString('type') //活动详情页

          if (data === '1') {
            showTigerGamePopup()
          } else if (data === '2' && type !== '19') {
            if (enterSideBar) return
            const list = selectedActivity().handleEventActivity('19')
            router.push({
              path: '/eventsDetail',
              query: {
                id: list[0].id,
                type: list[0].activityType,
              },
            })
          } else if (data === '3') {
            WithdrawSuccessPop()
          }
          this.loginInProress = false
          return data
        }
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    // 获取剩余抽奖次数
    async freeWithdrawTurnCount() {
      try {
        const { data } = await freeWithdrawTurnCountApi()
        this.turnCount = data
        return data
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    // 转盘抽奖
    async freeWithdrawTurn() {
      try {
        const { data } = await freeWithdrawTurnApi()
        this.prizeAmount = data
        return data
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

    /**
     * 获取用户正在参加的活动信息进度
     */
    async fetchActivityProgress() {
      try {
        const { data } = await getActivityProgress()

        this.activityProgress = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
     * 获取所有用户提现成功记录
     */
    async fetchWithdrawalRecords(params) {
      if (this.withdrawalRecords.length) {
        return this.withdrawalRecords
      }
      try {
        const { data } = await getWithdrawalRecords({
          pageSize: 60,
          ...params,
        })
        this.withdrawalRecords = data.pageData
        return data.pageData
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取所有用户提现成功记录
     */
    async freeWithdrawDetailRecords(params) {
      if (this.withdrawDetailRecords.length) {
        return this.withdrawDetailRecords
      }
      try {
        const { data } = await freeWithdrawDetailRecordsApi({
          pageSize: 60,
          ...params,
        })
        this.withdrawDetailRecords = data.pageData
        return data.pageData
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
})

export default useFreeWithdraw
