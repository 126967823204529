import _objectSpread from "E:/pg/brlwon7-site/node_modules/.pnpm/@babel+runtime@7.24.0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { ref, reactive, onMounted } from 'vue';
import useUserInfoStore from '@/store/modules/userInfo';
import useLocalCache from '@/hooks/storage/localStorage';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive，等……start*****/

export default {
  props: {
    callback: {
      type: Function,
      // 成功回调
      default: null
    }
  },
  emits: ['tabTypeFun'],
  setup(__props, {
    expose,
    emit
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const {
      getAccountInfo
    } = useLocalCache();
    // 用户信息
    const userInfoStore = useUserInfoStore();
    // props 关闭弹出方法

    // 表单实例
    const formRef = ref();
    // 表单数据
    const formState = reactive({
      customName: '',
      // 账号
      password: '' // 密码
    });
    // 是否记住账号密码
    const checked = ref(true);
    // 显示隐藏密码
    const showPassword = ref(false);
    // 请求 加载
    const isLoading = ref(false);

    /** ***ref、reactive，等……end*****/

    /** ***函数 start*****/
    // 去注册
    const tabTypeFun = () => {
      emit('tabTypeFun', 'register');
    };
    // 提交登录
    const handleFinish = async () => {
      isLoading.value = true;
      try {
        const result = await userInfoStore.loginApiFun(_objectSpread(_objectSpread({}, formState), {
          checked: checked.value
        }));
        isLoading.value = false;
        props.callback && props.callback(result);
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    };
    // 密码
    const validatePassword = async (rule, value) => {
      if (!value) {
        return Promise.reject(t('logAndReg.login.tips1'));
      } else if (value.length < 8 || value.length > 16) {
        return Promise.reject(t('logAndReg.login.tips2'));
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      customName: [{
        required: true,
        message: t('logAndReg.login.field1'),
        trigger: 'change'
      }],
      password: [{
        required: true,
        validator: validatePassword,
        trigger: 'change'
      }]
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(() => {
      if (getAccountInfo()) {
        const {
          customName,
          password
        } = getAccountInfo();
        formState.customName = customName;
        formState.password = password;
      }
    });
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      emit,
      getAccountInfo,
      userInfoStore,
      props,
      formRef,
      formState,
      checked,
      showPassword,
      isLoading,
      tabTypeFun,
      handleFinish,
      validatePassword,
      rules,
      ref,
      reactive,
      onMounted,
      useUserInfoStore,
      useLocalCache,
      useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};