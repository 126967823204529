// app设置
import { defineStore } from 'pinia'
import addToDesk from '@/componentsFun/AddToDesk/index'
import { isIOS, myWindowOpen } from '@/utils/index'
import useCacheData from '@/store/modules/cacheData'
import useCopyWriter from '@/store/modules/copywriter'
import useAppStore from '@/store/modules/app'
const usePromptStore = defineStore('beforeinstallprompt', {
  state: () => ({
    pwaSwitch:false,// 首页底部点击安装默认false
    AndroidLink: '', // 安卓下载地址 通过顶部导航栏设置进去
    deferredPrompt: null, // 盘点是否安装
    notInApp: window.AndroidEM ? true : false, // 返回null 就不在安卓系统
  }),
  actions: {
    init() {
      // 初始化监听
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.deferredPrompt = e
      })
    },
    down() {
      if (isIOS()) {
        addToDesk()
        return
      }
      this.open()
      // if (this.AndroidLink) {
      //   myWindowOpen(`${this.AndroidLink}?time=${Date.parse(new Date())}`)
      //   return
      // }
    },
    open() {
      if (isIOS()) {
        addToDesk()
        return
      }
      // 打开安装弹出
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt()
        this.deferredPrompt.userChoice.then((result) => {
          if (result.outcome === 'dismissed') {
            console.log('User refuses to install')
          } else if (result.outcome === 'accepted') {
            this.deferredPrompt = false
          }
        })
      }
    },
    pwaSwitchFun(type){
      this.pwaSwitch = type
    },
    // apk下载需要通过接口配置
    AndroidLinkFun() {
      if (useCopyWriter().site === 'yes') {
        try {
          // 多站点数据
          const list = useCopyWriter().copyWriterMap['topmost-part'].langData[`${useAppStore().language}`]
          list.forEach((item) => {
            if (item.text) {
              //如果url地址有apk就过滤不要
              if (item?.linkValue && item?.linkValue.includes('apk')) {
                this.AndroidLink = item.linkValue
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          useCacheData().alertConfig.forEach((item) => {
            if (item.externalLinks && item.externalLinks.endsWith('apk')) {
              this.AndroidLink = item.externalLinks
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
})

export default usePromptStore
