import _objectSpread from "E:/pg/brlwon7-site/node_modules/.pnpm/@babel+runtime@7.24.0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { ref, reactive, onMounted } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import MyPullRefreshList from '@/components/MyPullRefreshList/index.vue';
import MyEmptyData from '@/components/MyEmptyData/index.vue';
import CellItem from './components/CellItem.vue';
import rechargePopup from './index.js';
import dayjs from 'dayjs';
import { rechargeListApi, rechargeRecordsTotalApi } from '@/api/recharge/recharge.js';
import { formatDateTimer, moneyFormat } from '@/utils/index.js';
import useAppStore from '@/store/modules/app.js';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/
import useDictStore from '@/store/modules/dict.js';
export default {
  setup(__props, {
    expose
  }) {
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const show = ref(false);
    const appStore = useAppStore();
    const dictStore = useDictStore();
    const state = reactive({
      listData: [],
      // 列表数据
      loading: false,
      //分页是否加载中
      finished: false,
      // 已经完成所有加载
      dateValue: 0,
      // 选择的时间
      rechargeTotal: 0,
      // 充值总额
      // 选择时间列表
      dateOptions: dictStore.dateSelectData,
      // 分页
      pagination: {
        current: 1,
        pageSize: 20,
        total: 0
      }
    });
    expose({
      show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    let loadingDiabled = false;
    const onLoad = () => {
      if (state.finished || loadingDiabled) return false;
      rechargeListHttp();
    };
    // 请求充值列表接口
    const rechargeListHttp = async () => {
      loadingDiabled = true;
      state.loading = true;
      let params = _objectSpread({
        pageNum: state.pagination.current,
        pageSize: state.pagination.pageSize
      }, getDateParams());
      try {
        let {
          data
        } = await rechargeListApi(params);
        state.listData.push(...processData(data.pageData));
        state.pagination.total = data.total;
        state.pagination.current++;
      } finally {
        loadingDiabled = false;
        state.loading = false;
        if (state.listData.length >= state.pagination.total) {
          state.finished = true;
        }
      }
    };
    // 请求充值记录总额接口
    const rechargeRecordsTotalHttp = async () => {
      let {
        data
      } = await rechargeRecordsTotalApi(getDateParams());
      state.rechargeTotal = data;
    };

    // 获取时间参数
    const getDateParams = () => {
      let params = {};
      // 不等于全部时
      if (state.dateValue !== null) {
        let startDate = dayjs().add(state.dateValue, 'day').format('YYYY-MM-DD');
        let endDate = dayjs().format('YYYY-MM-DD');
        // 昨天
        if (state.dateValue === -1) {
          endDate = startDate;
        }
        params = {
          'dateStrList[0]': startDate,
          'dateStrList[1]': endDate
        };
      }
      return params;
    };

    // 处理列表数据
    const processData = (data = []) => {
      return data.map(item => {
        item.stateText = [t('recharge.status.onGoing'), t('recharge.status.success'), t('recharge.status.fail')][item.state];
        item.typeText = [t('recharge.depositingOnThePlatform'), t('recharge.deposit'), t('recharge.activeDeposits'), t('recharge.activeDeposits')][item.type];
        item.color = ['var(--color-auxiliary)', 'var(--color-green)', 'var(--color-red)'][item.state];
        item.date = formatDateTimer(item.createTime, 'YYYY-MM-DD HH:mm:ss');
        return item;
      });
    };

    // 筛选时间
    const handleDateChange = () => {
      rechargeRecordsTotalHttp();
      state.listData = [];
      state.pagination.current = 1;
      state.finished = false;
      onLoad();
    };

    // 销毁组件
    const unMountComponent = () => {
      rechargePopup({
        type: 'unmount'
      });
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(() => {
      rechargeRecordsTotalHttp();
    });
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      show,
      appStore,
      dictStore,
      state,
      loadingDiabled,
      onLoad,
      rechargeListHttp,
      rechargeRecordsTotalHttp,
      getDateParams,
      processData,
      handleDateChange,
      unMountComponent,
      ref,
      reactive,
      onMounted,
      MyPopup,
      MyPullRefreshList,
      MyEmptyData,
      CellItem,
      rechargePopup,
      dayjs,
      rechargeListApi,
      rechargeRecordsTotalApi,
      formatDateTimer,
      moneyFormat,
      useAppStore,
      useI18n,
      useDictStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};