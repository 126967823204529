import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1e048c88"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "userinfo__center",
  id: "userinfo__center"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  key: 0,
  class: "dot"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (items, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "userinfo__list",
      key: index
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, item => {
      return _openBlock(), _createElementBlock("div", {
        key: item.name,
        class: "userinfo__item",
        onClick: item.fn
      }, [_createElementVNode("i", {
        class: _normalizeClass(["iconfont", item.iconClass]),
        style: _normalizeStyle({
          color: item.color
        })
      }, null, 6 /* CLASS, STYLE */), _createElementVNode("span", null, _toDisplayString(item.name), 1 /* TEXT */), item.hasDot && _ctx.userInfoStore.userInfo.messageUnReadCount ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.userInfoStore.userInfo.messageUnReadCount), 1 /* TEXT */)])) : _createCommentVNode("v-if", true)], 8 /* PROPS */, _hoisted_2);
    }), 128 /* KEYED_FRAGMENT */))]);
  }), 128 /* KEYED_FRAGMENT */))]);
}