import request from '@/utils/request'

// 系统消息
export const getSystemMessageListApi = (params) =>
  request({
    url: '/kwai/app/bulletinManage/list',
    method: 'get',
    params,
    loading: false,
  })
// 奖金消息
export const newBoxFlow = (data) =>
  request({
    url: '/kwai/app/activity/newBoxFlow',
    method: 'post',
    data,
    loading: true,
  })

// 消息列表
export const getMessageListApi = (params) =>
  request({
    url: '/kwai/app/custom/message/list',
    method: 'get',
    params,
    loading: false,
  })
// 消息计数
export const getMessageUnReadCountApi = (params) =>
  request({
    url: '/kwai/app/custom/message/getUnReadCount',
    method: 'get',
    params,
    loading: false,
  })

// 设置已读
export const messageReadApi = (id) =>
  request({
    url: `/kwai/app/custom/message/read?id=${id}`,
    method: 'post',
    loading: false,
  })
