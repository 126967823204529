// import i18n from '@/i18n'
// 埋点

// 注册
export const completeRegistration_Buried = () => {
  window?.AndroidEM?.onEvent?.('register_empressbet')
  window?.AndroidEM?.firebaseEvent?.('REGISTER')
  window.global_pixel_id && window.kwaiq?.instance(window.global_pixel_id)?.track?.('completeRegistration')
  window?.ttq?.track?.('CompleteRegistration')
  window?.fbq?.('track', 'CompleteRegistration')
  window?.gtag?.('event', 'sign_up')
}

// 登录
export const login_Buried = () => {
  window?.AndroidEM?.firebaseEvent('LOGIN')
  window?.gtag?.('event', 'login')
}

// 充值
export const addToCart_Buried = (amount = 0, currency = 'BLR') => {
  window?.AndroidEM?.onEvent?.('pull_empressbet')
  window?.AndroidEM?.firebaseEvent?.('ADD_TO_CART')
  window?.spinslots?.cusTrack?.(JSON.stringify({ name: 'pull_empressbet' }))
  window.global_pixel_id && window.kwaiq?.instance(window.global_pixel_id)?.track?.('addToCart')
  window?.ttq?.track?.('PlaceAnOrder')
  window?.fbq?.('track', 'AddToCart')
  window?.gtag?.('event', 'add_to_cart', {
    value: amount,
    currency,
  })
}

// 完成首次充值
// export const firstRecharge_Buried = (amount = 0, moneyUnit = i18n.t('pay.moneyUnit')) => {
//   window?.AndroidEM?.onPurchase?.(amount, moneyUnit)
//   window?.AndroidEM?.firebaseEvent?.('FIRST_RECHARGE', JSON.stringify(amount))

//   window?.spinslots?.cusTrack?.(
//     JSON.stringify({
//       name: 'onPurchase',
//       amount,
//       unit: moneyUnit,
//     })
//   )

//   window.global_pixel_id && window.kwaiq?.instance(window.global_pixel_id)?.track?.('purchase')
//   window?.ttq?.track?.('CompletePayment', {
//     value: amount,
//     currency: moneyUnit,
//     content_id: '1',
//   })

//   window?.fbq?.('track', 'Purchase', {
//     value: amount,
//     currency: moneyUnit,
//   })

//   window?.gtag?.('event', 'purchase', {
//     value: amount,
//     currency: moneyUnit,
//   })

//   // 完成充值，删除快手的缓存
//   localStorage.removeItem('kwaiSearch')
//   delete window.global_pixel_id
// }

// 完成非首次充值，目前只有马甲包用到
export const notFirstRecharge_Buried = (amount = 0) => {
  window?.AndroidEM?.onEvent?.('recharge_empressbet')
  window?.AndroidEM?.firebaseEvent?.('SECOND_RECHARGE', JSON.stringify(amount))

  window?.spinslots?.cusTrack?.(JSON.stringify({ name: 'recharge_empressbet' }))
}

// 浏览页面
export const contentView_Buried = () => {
  window.global_pixel_id && window.kwaiq?.instance(window.global_pixel_id)?.track?.('contentView')
  window?.ttq?.track?.('ViewContent')
  window?.fbq?.('track', 'ViewContent')
  window?.gtag?.('event', 'page_view')
}
