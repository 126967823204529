import io from 'socket.io-client'
import useUserInfoStore from '@/store/modules/userInfo'
import useSocketStore from '@/store/modules/socket'
import { random } from '@/utils/index'

// socket连接地址
const socketUrl = process.env.VUE_APP_WSS || `wss://${window.location.host}`

// socket实例
const socket = {
  curSocket: null, // 当前socket实例
  // 订阅事件统计
  topicList: [],
  // 判断socket是否初始化
  isSocketInit: () => {
    if (!socket.curSocket) {
      console.log('socket...请先初始化')
      return false
    }
    return true
  },
  // 初始化socket
  socketInit: (tk, id) => {
    // 获取store中数据源
    const userInfoStore = useUserInfoStore()
    // 获取token
    const token = tk || userInfoStore.token
    // 获取登录标识---请修改为自己项目用户id
    const userId = id || userInfoStore.userId

    // 拼接socket参数
    let str = 'userId='
    if (token && userId) {
      // 登录状态，拼接参数：用户id和token
      str += userId + '&authorization=' + token
    } else {
      // 没有token，游客状态，youke + 7位随机数
      str += `youke${random(7)}`
    }

    // socket配置
    const options = {
      autoConnect: false, // 已通过验证，全局使用可默认打开，组件内使用则默认关闭，使用时在打开
      path: '/socket.io/',
      forceNew: true,
      transports: ['websocket'],
      query: str,
    }
    // 创建socket实例，获取socket实例下的方法，并存到全局
    socket.curSocket = io(socketUrl, options)

    if (process.env.VUE_APP_ENV === "development") {
      // socket建立连接成功
      socket.curSocket.on("connect", () => {
        // console.log('socket...建立连接成功', socket.curSocket.id)
      });
      // socket已断开
      socket.curSocket.on("disconnect", () => {
        // console.log('socket...已断开', socket.curSocket.id)
      });
    }
  },
  // 打开socket
  socketOpen: () => {
    if (!socket.isSocketInit()) {
      return
    }
    socket.curSocket.open()
    // console.log('socket...已开启')
  },
  // 发送消息，msg消息内容，e发送事件（与后端约定的）
  socketSendMsg: (channel, params) => {
    if (!socket.isSocketInit()) {
      return
    }
    // console.log('channel', channel)
    // console.log('params', params)
    socket.curSocket.emit(channel || 'message', params)
    // console.log('socket...已发送', params)
  },
  // 关闭socket
  socketClose: () => {
    if (!socket.isSocketInit()) {
      return
    }
    socket.curSocket.close()
    // console.log('socket...已关闭')
  },
  // 监听内容
  listener: (topic, res) => {
    // console.log('socket...已接收来自' + topic + '事件的数据')
    // 获取store中数据源
    const socketStore = useSocketStore()
    // 触发store中对应通道事件
    socketStore[topic](res)
  },
  // 添加默认订阅事件
  socketSubDefaultTopic: () => {
    // console.log('socket...添加默认订阅事件')
    // 获取store中数据源
    const socketStore = useSocketStore()
    socketStore.defaultTopicList.forEach((item) => {
      socket.socketSubTopic(item)
    })
  },
  // 订阅内容
  socketSubTopic: (topic) => {
    if (!socket.isSocketInit()) {
      return
    }
    // 监听订阅事件，并做去重处理
    if (!socket.topicList.includes(topic)) {
      socket.topicList.push(topic)
      console.log('socket...已订阅' + topic + '事件')
      // 监听订阅事件
      socket.curSocket.on(topic, (res) => socket.listener(topic, res))
    }
  },
  // 清除单个订阅
  socketClearTopic: (topic) => {
    // 移除绑定事件
    if (socket.curSocket.id) {
      socket.curSocket.off(topic, socket.listener)
      // console.log('socket...已清除订阅', topic)
    }
  },
  // 清除单个订阅，并关闭socet
  socketClearTopicAndSocket: (topic) => {
    if (socket.curSocket.id) {
      // 关闭socket
      socket.curSocket.close()
      // 移除绑定事件
      socket.curSocket.removeAllListeners(topic)
      // console.log('socket...已清空订阅，并关闭了socet', topic)
    }
  },
  // 清除所有订阅
  socketClearAll: () => {
    if (socket.curSocket) {
      // 关闭socket
      socket.curSocket.close()
      // 移除绑定事件
      socket.curSocket.removeAllListeners()
      socket.curSocket = null
      // console.log('socket...已清空订阅')
    }
  },
  // socket重启
  socketReload: (token, userId) => {
    // console.log('socket...已重启')
    socket.socketClearAll()
    socket.socketInit(token, userId)
    socket.socketOpen()
    socket.topicList = []
    socket.socketSubDefaultTopic()
  },
}

export default socket
