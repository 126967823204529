import useUserInfoStore from '@/store/modules/userInfo.js'
import lotteryCode from '@/views/activity/events/details/components/lotteryCode/index'
import { fetchTgLotteryStatus } from '@/api/tgLottery/index.js'
const TGLottery = () => {
  // 用户信息
  const userInfo = useUserInfoStore()

  const getTGLottery = async () => {
    if (!userInfo.token) {
      // 检测到用户未登录, 获取活动是否开启, 开启, 弹出领取彩金弹窗
      try {
        const res = await fetchTgLotteryStatus()
        if (res.data === '0') {
          // 展示领取弹窗, 用户点击弹窗弹出登录按钮
          lotteryCode()
          return
        }
      } catch (error) {
        console.error(error)
      }
    }
    lotteryCode()
    return
  }

  return {
    getTGLottery,
  }
}

export default TGLottery
