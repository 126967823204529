import MyPopup from '@/components/MyPopup';
import { ref, onMounted } from 'vue';
import useUserInfoStore from '@/store/modules/userInfo.js';
import { receive, freeWithdrawCustomerRecordsApi } from '@/api/FreeWithdraw/index.js';
import useFreeWithdraw from '@/store/modules/freeWithdraw.js';
import { moneyFormat, getMaxZIndex } from '@/utils';
import useAppStore from '@/store/modules/app';
import success from './index.js';
import 'animate.css';
/** ***引入相关包end*****/

/** ***ref、reactive，等……start*****/

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    freeWithdrawId: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    const props = __props;
    /** ***引入相关包start*****/

    const appStore = useAppStore();
    // 火箭
    const rocketVisible = ref(false);
    // 领取
    const receiveVisible = ref(false);
    const store = useFreeWithdraw();
    // 有提现记录
    const flag = ref(false);
    const isVisible = ref(true);
    expose({
      isVisible
    });
    /** ***ref、reactive，等……end*****/

    /** ***函数 start*****************/
    const startAnimation = async () => {
      if (props.freeWithdrawId) {
        // 火箭入场
        rocketVisible.value = true;
        // 等待入场动画完成
        await awaitTime(2300);

        // 火箭离场
        rocketVisible.value = false;
        // 等待离场动画完成
        await awaitTime(1000);
      }

      // 显示领取
      receiveVisible.value = true;
    };

    // 改写settimeout为promise形式
    const awaitTime = second => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(true);
        }, second);
      });
    };

    // 领取成功的金额
    const receiveMoneySingle = ref(0);
    const handleReceive = async () => {
      if (!props.freeWithdrawId) {
        unMountComponent();
        return;
      }
      store.freeWithdrawTurnCount();
      const userInfo = useUserInfoStore();
      userInfo.updateBalance();
      unMountComponent();
    };

    // 获取用户的活动记录
    const customerListMoney = ref(0);
    const getCustomerList = async () => {
      const res = await freeWithdrawCustomerRecordsApi({
        pageNum: 1,
        pageSize: 100
      }).catch(() => false);
      if (res === false || res.code !== 200) return;
      const {
        pageData
      } = res.data;
      const list = [];
      pageData.length && pageData.forEach(item => {
        if (item.attendCount === '2') {
          list.push(item);
        }
      });
      if (!list.length && pageData.length) {
        flag.value = false;
        customerListMoney.value = pageData[0].totalRewardAmount;
      } else if (list.length && pageData.length) {
        flag.value = true;
        customerListMoney.value = list[0].realRewardAmount;
      }
    };
    const unMountComponent = () => {
      props.callback && props.callback();
      success({
        type: 'unmount'
      });
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(async () => {
      // 有抽奖次数直接展示领取金额
      if (store.attendStatus !== '3') {
        const res = await receive({
          freeWithdrawId: props.freeWithdrawId
        }).catch(() => false);
        if (res === false || res.code !== 200) return;
        receiveMoneySingle.value = res.data;
      } else {
        if (!props.freeWithdrawId) {
          getCustomerList();
        }
      }
      startAnimation();
    });
    /** ***生命周期end*****/

    const __returned__ = {
      props,
      appStore,
      rocketVisible,
      receiveVisible,
      store,
      flag,
      isVisible,
      startAnimation,
      awaitTime,
      receiveMoneySingle,
      handleReceive,
      customerListMoney,
      getCustomerList,
      unMountComponent,
      MyPopup,
      ref,
      onMounted,
      useUserInfoStore,
      receive,
      freeWithdrawCustomerRecordsApi,
      useFreeWithdraw,
      moneyFormat,
      getMaxZIndex,
      useAppStore,
      success
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};