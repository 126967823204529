import { myFixed } from '@/utils/index.js';
import useAppStore from '@/store/modules/app.js';
import CellItem from './CellItem.vue';
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    active: {
      type: Number,
      default: 0
    },
    selectData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup(__props, {
    expose,
    emit
  }) {
    expose();
    const appStore = useAppStore();
    const handleClick = (index, item) => {
      emit('change', index, item);
    };
    const __returned__ = {
      appStore,
      emit,
      handleClick,
      myFixed,
      useAppStore,
      CellItem
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};