import useAppStore from '@/store/modules/app.js';
export default {
  setup(__props, {
    expose
  }) {
    expose();
    const appStore = useAppStore();
    const __returned__ = {
      appStore,
      useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};