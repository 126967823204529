import request from "@/utils/request";
/**
 * 获取 overview 游戏分类和游戏
 * @param
 * @returns
 */
export const tabGameList = (params) =>
  request({
    url: "/kwai/api/tab/list",
    method: "get",
    params,
  });

/**
 * 游戏收藏
 * @returns
 */
export async function favoriteAdd(data) {
  return await request({
    url: "/kwai/app/game/favorite/add",
    method: "post",
    data,
  });
}

/**
 * 取消游戏收藏
 * @param
 * @returns
 */
export const favoriteCancel = (data) =>
  request({
    url: `/kwai/app/game/favorite/cancel`,
    method: 'DELETE',
    data,
  })

/**
 * 获取 overview 游戏分类和游戏
 * @param
 * @returns
 */
export const getProviderType = (params) =>
  request({
    url: "/kwai/api/game/getProviderType",
    method: "get",
    params,
  });

/**
 * 获取 热门游戏
 * @param
 * @returns
 */
export const hotList = (params) =>
  request({
    url: "/kwai/api/game/hot/list",
    method: "get",
    params,
  });
/**
 * 获取 最近玩的游戏
 * @param
 * @returns
 */
export const historyList = (params) =>
  request({
    url: "/kwai/app/game/history/list",
    method: "get",
    params,
  });
/**
 * 获取 获取收藏夹记录
 * @param
 * @returns
 */
export const favoriteList = (params) =>
  request({
    url: "/kwai/app/game/favorite/list",
    method: "get",
    params,
  });
/**
 * 获取 系统返回游戏的分页
 * @param
 * @returns
 */
export const gameList = (params) =>
  request({
    url: "/kwai/api/tab/game/list",
    method: "get",
    params,
  });
  

  /**
 * 首页顶部消息提示
 * @param {}
 * @returns
 */
export const reqTopList = (params) =>
  request({
    url: '/kwai/api/top/list',
    method: 'get',
    params,
  })

/**
 * 进入游戏界面请求游戏信息
 * @param {}
 * @returns
 */
export const reqevoLauncher = (data) =>
  request({
    url: '/kwai/app/evoplay/launcher',
    method: 'post',
    data,
    message: true,
  })

/**
 * 进入游戏界面请求游戏信息
 * @param {}
 * @returns
 */
export const reqgmagLauncher = (data) =>
  request({
    url: '/kwai/app/gmag/launcher',
    method: 'post',
    data,
    message: true,
  })

/**
 * 进入游戏界面请求游戏信息
 * @param {}
 * @returns
 */
export const reqpgLauncher = (data) =>
  request({
    url: '/kwai/app/pg/game/launcherHtml',
    method: 'post',
    data,
    message: true,
  })

/**
 * 进入游戏界面请求游戏信息
 * @param {}
 * @returns
 */
export const reqppLauncher = (data) =>
  request({
    url: '/kwai/app/pp/game/launcher',
    method: 'post',
    data,
    message: true,
  })

/**
 * 进入新游戏界面请求游戏信息
 * @param {}
 * @returns
 */
export const reqGameLauncher = (data) =>
  request({
    url: '/tripartite/app/game/launcher',
    method: 'post',
    data,
    message: true,
  })
  /**
 * 进入游戏界面请求游戏信息
 * @param {}
 * @returns
 */
export const reqawcLauncher = (data) =>
  request({
    url: '/tripartite/app/awc/launcher',
    method: 'post',
    data,
    message: true,
  })
