import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-57ca510e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-empty-data"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "img", {}, () => [_createElementVNode("img", {
    src: $props.img || $setup.data[$props.type]['img']
  }, null, 8 /* PROPS */, _hoisted_2)], true), _renderSlot(_ctx.$slots, "text", {}, () => [_createElementVNode("span", null, _toDisplayString($props.text || $setup.data[$props.type]["text"]), 1 /* TEXT */)], true)]);
}