import request from '@/utils/request'

/**
 * 查询vip升级开关
 * @returns Promise
 */
export const fetchExpSwitch = () => {
  return request({
    url: 'kwai/api/vip/expSwitch',
    method: 'get',
  })
}

/**
 * 查询vip升级进度
 * @returns Promise
 */
export const fetchVipProgress = () => {
  return request({
    url: 'kwai/app/vip/progress',
    method: 'post',
  })
}

/**
 * 获取vip 列表
 * @param
 * @returns
 */
export const getVipList = (data) =>
  request({
    url: '/kwai/api/vip/info',
    method: 'post',
    data,
  })
/**
 * 获取 vip经验
 * @param
 * @returns
 */
export const getVipExp = (params) =>
  request({
    url: '/kwai/api/vip/exp',
    method: 'get',
    params,
  })

/**
 * 获取 周，月彩金 列表
 * @param
 * @returns
 */
export const getGoldList = (params) =>
  request({
    url: '/kwai/app/custom/color/gold/list',
    method: 'get',
    params,
  })
export const getNoLoginGoldList = (params) =>
  request({
    url: '/kwai/api/custom/color/gold/list',
    method: 'get',
    params,
  })
/**
 * 领取 周，月彩金
 * @param
 * @returns
 */
export const drawGold = (data) =>
  request({
    url: '/kwai/app/custom/color/gold/draw',
    method: 'post',
    data,
  })
/**
 * 获取奖金列表
 * @param
 * @returns
 */
export const getReceivedList = (params) =>
  request({
    url: '/kwai/app/vip/received/list',
    method: 'get',
    params,
  })
/**
 * 领取奖金
 * @param
 * @returns
 */
export const pickupReceived = (data) =>
  request({
    url: '/kwai/app/vip/pickup',
    method: 'post',
    data,
  })
/**
 * vip 升级
 * @param
 * @returns
 */
export const vipUp = (data) =>
  request({
    url: '/kwai/app/vip/levelUp',
    method: 'post',
    data,
  })
