// 长期级别存储
import store from 'store2'
export default function useLocalCache() {
  // 0.清除所有缓存
  function clearCache() {
    store.clearAll()
  }
  // 账号密码
  // 获取账号密码
  function getAccountInfo() {
    return store.get('accountInfo')
  }
  // 设置账号密码
  function setAccountInfo(res) {
    return store.set('accountInfo', res)
  }
  // 移除账号密码
  function removeAccountInfo() {
    store.remove('accountInfo')
  }

  // 1.获取token
  function getCacheToken() {
    return store.get('token')
  }
  // 2.设置Token
  function setCacheToken(res) {
    return store.set('token', res)
  }
  // 3.移除Token
  function removeCacheToken() {
    store.remove('token')
  }
  // 1.获取userId start
  function getCacheUserId() {
    return store.get('userId')
  }
  // 2.设置userId
  function setCacheUserId(res) {
    store.set('userId', res)
  }
  // 3.移除userId end
  function removeCacheUserId() {
    store.remove('userId')
  }

  // 1.获取国际化 start
  function getLanguage() {
    return store.get('language')
  }
  // 2.设置国际化
  function setLanguage(res) {
    store.set('language', res)
  }
  // 3.移除国际化
  function removeLanguage() {
    store.remove('language')
  }

  // 1.埋点专用key start
  function getLocationSearch() {
    return store.get('locationSearch')
  }
  // 2.设置埋点专用key
  function setLocationSearch(res) {
    store.set('locationSearch', res)
  }
  // 3.移除埋点专用key
  function removeLocationSearch() {
    store.remove('locationSearch')
  }

  // 1.首充弹出框本地记录专用key start 组件是FirstTime
  function getLocationFirstTime() {
    return store.get('FirstTime')
  }
  // 2.设置首充弹出框本地用key组件是FirstTime
  function setLocationFirstTime(res) {
    store.set('FirstTime', res)
  }
  // 3.移除首充弹出框本地key组件是FirstTime
  function removeLocationFirstTime() {
    store.remove('FirstTime')
  }

  //设置首页下载导航栏
  function setCloseDownload(res) {
    store.set('hasCloseDownload', res)
  }

  //获取首页下载导航栏
  function getCloseDownload() {
    return store.get('hasCloseDownload')
  }

  //移除首页下载导航栏
  function removeDownload() {
    store.remove('hasCloseDownload')
  }

  return {
    getAccountInfo,
    setAccountInfo,
    removeAccountInfo,
    getCacheToken,
    setCacheToken,
    removeCacheToken,
    clearCache,
    getCacheUserId,
    setCacheUserId,
    removeCacheUserId,
    getLanguage,
    setLanguage,
    removeLanguage,
    getLocationSearch,
    setLocationSearch,
    removeLocationSearch,
    getLocationFirstTime,
    setLocationFirstTime,
    removeLocationFirstTime,
    setCloseDownload,
    getCloseDownload,
    removeDownload,
  }
}
