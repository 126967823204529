import request from '@/utils/request'

/**
 * 获取客服列表
 * @returns
 */
export const reqServerList = (params) => {
  return request({
    url: '/kwai/api/support/type',
    method: 'get',
    params,
  })
}
/**
 * 获取客服列表
 * @returns
 */
export const reqContactInfo = (params) => {
  return request({
    url: '/kwai/api/support/one',
    method: 'get',
    params,
  })
}
