import request from '@/utils/request'

// 获取活动的状态，（活动入口是否开启）
export const freeWithdrawActivityStatusApi = () =>
  request({
    url: '/kwai/api/freeWithdraw/activity/getActivityStatus',
    method: 'Get',
  })
// 获取活动的参加状态//1-可以参加；2-参加活动中；3-不能参加
export const freeWithdrawAttendStatusApi = () =>
  request({
    url: '/kwai/app/freeWithdraw/activity/getAttendStatus',
    method: 'Get',
  })

// 获取剩余抽奖次数
export const freeWithdrawTurnCountApi = () =>
  request({
    url: '/kwai/app/freeWithdraw/activity/getTurnCount',
    method: 'Get',
  })
// 转盘抽奖
export const freeWithdrawTurnApi = () =>
  request({
    url: '/kwai/app/freeWithdraw/activity/turn',
    method: 'Post',
  })

/**
 * 获取用户正在参加的活动信息
 */
export const getActivityProgress = () =>
  request({
    url: '/kwai/app/freeWithdraw/activity/getAttendingRecord',
    method: 'GET',
  })

/**
 * 获取所有用户提现成功记录
 * @param {*} params
 * @returns Promise
 */
export const getWithdrawalRecords = (params) =>
  request({
    url: '/kwai/api/freeWithdraw/activity/list',
    method: 'GET',
    params,
  })

/**
 * 领取提现金额
 * @param {*} data
 * @returns
 */
export const receive = (data) =>
  request({
    url: '/kwai/app/freeWithdraw/activity/receive',
    method: 'POST',
    data,
  })

/*
 * 分页获取明细记录
 * @param {*} params
 * @returns Promise
 */
export const freeWithdrawDetailRecordsApi = (params) =>
  request({
    url: '/kwai/app/freeWithdraw/activity/detail/list',
    method: 'GET',
    params,
  })

/*
 * 分页获取明细记录
 * @param {*} params
 * @returns Promise
 */
export const freeWithdrawCustomerRecordsApi = (params) =>
  request({
    url: '/kwai/app/freeWithdraw/activity/custom/list',
    method: 'GET',
    params,
  })
/*
 * 获取转盘抽奖选项
 * @param {*} params
 * @returns Promise
 */
export const freeWithdrawActivityRecordsApi = (params) =>
  request({
    url: '/kwai/api/freeWithdraw/activity/getTurnList',
    method: 'GET',
    params,
  })
/*
 * 要获取的手机记录数
 * @param {*} params
 * @returns Promise
 */
export const freeWithdrawMobileListRecordsApi = (params) =>
  request({
    url: '/kwai/app/freeWithdraw/activity/getMobileList',
    method: 'GET',
    params,
  })
