import useAppStore from '@/store/modules/app.js';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    img: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'order' // 默认数据类型为order
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const appStore = useAppStore();
    const {
      t
    } = useI18n();

    // 空数据类型，其他空数据类型可以这边定义方便以后更改
    const data = {
      order: {
        img: require(`./images/order-empty${appStore.themeSystem === 'theme7119' ? '2' : ''}.png`),
        text: t('other.noRecords')
      }
    };
    const __returned__ = {
      appStore,
      t,
      data,
      useAppStore,
      useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};