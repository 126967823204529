import { computed, ref, onUnmounted } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import useAppStore from '@/store/modules/app.js';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    //头部内容
    headerText: {
      type: String,
      default: ''
    },
    container: {
      type: String,
      default: ''
    },
    selfContainer: {
      type: Object,
      default: null
    },
    //左边按钮内容
    leftText: {
      type: String,
      default: ''
    },
    //右边按钮内容
    rightText: {
      type: String,
      default: ''
    },
    //关闭
    close: {
      type: Function,
      default: null
    },
    //左边按钮事件
    leftClickEvent: {
      type: Function,
      default: null
    },
    //右边按钮事件
    rightClickEvent: {
      type: Function,
      default: null
    }
  },
  emits: ['update:modelValue', 'close', 'leftClickEvent', 'rightClickEvent'],
  setup(__props, {
    expose,
    emit
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/

    const appStore = useAppStore();
    let show = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      }
    });
    const isLoading = ref(false);
    const isLoadingTime = ref(false);

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    const leftClickEvent = () => {
      isLoading.value = true;
      props.leftClickEvent();
      isLoadingTime.value = setTimeout(() => {
        isLoading.value = false;
      }, 1000);
    };
    const rightClickEvent = () => {
      props.rightClickEvent();
    };
    const handleClose = () => {
      props.close();
    };
    const handeleArr = str => str === null || str === void 0 ? void 0 : str.split('\n');
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onUnmounted(() => {
      if (isLoadingTime.value) clearTimeout(isLoadingTime.value);
    });
    /** ***生命周期end*****/

    const __returned__ = {
      emit,
      appStore,
      props,
      show,
      isLoading,
      isLoadingTime,
      leftClickEvent,
      rightClickEvent,
      handleClose,
      handeleArr,
      computed,
      ref,
      onUnmounted,
      MyPopup,
      useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};