import _objectSpread from "E:/pg/brlwon7-site/node_modules/.pnpm/@babel+runtime@7.24.0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import ptBR from 'ant-design-vue/es/locale/pt_BR';
import esES from 'ant-design-vue/es/locale/es_ES';
import enUS from 'ant-design-vue/es/locale/en_US';
import { onMounted, onUnmounted, watch, getCurrentInstance, computed } from 'vue';
import { reqServerList, reqContactInfo } from '@/api/serve/server.js';
// socket
import socket from '@/utils/socket';
import useAppStore from './store/modules/app.js';
import useCacheData from '@/store/modules/cacheData';
import useUserInfoStore from '@/store/modules/userInfo.js';
import LongTimeNoUse from '@/componentsFun/MySecondPopup/index';
import usePromptStore from '@/store/modules/beforeinstallprompt';
import { useI18n } from 'vue-i18n';
import { getChannerl } from '@/utils';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup(__props, {
    expose
  }) {
    expose();

    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    // 获取store
    const appStore = useAppStore();
    // 获取缓存数据
    const cacheData = useCacheData();
    // 用户信息
    const userInfo = useUserInfoStore();
    // 获取全局组件实例
    const {
      proxy
    } = getCurrentInstance();

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    // ant框架国际化语言
    const locale = computed(() => {
      if (process.env.VUE_APP_LANGUAGE_FRAME === 'pt-BR') {
        return ptBR;
      } else if (process.env.VUE_APP_LANGUAGE_FRAME === 'es-ES') {
        return esES;
      } else {
        return enUS;
      }
    });

    // 处理调用不同客服类型
    const handleServer = async (res, channel) => {
      // zendesk插件
      if (res.includes('zendesk')) {
        // 获取客服相关信息
        const {
          data: infoRes
        } = await reqContactInfo({
          type: 'zendesk',
          channel
        });
        // 动态引入和加载zendesk插件
        const scriptEl = document.createElement('script');
        scriptEl.id = 'ze-snippet';
        scriptEl.type = 'text/javascript';
        scriptEl.src = `https://static.zdassets.com/ekr/snippet.js?key=${infoRes.serviceUrl}`;
        scriptEl.onload = () => {
          const bodyObserver = new MutationObserver((mutations, observer) => {
            const launcherEl = document.querySelector('#launcher');
            if (launcherEl) {
              launcherEl.style.zIndex = -1;
            }
          });
          const bodyEl = document.querySelector('body');
          const options = {
            childList: true
          };
          bodyObserver.observe(bodyEl, options);
        };
        document.body.appendChild(scriptEl);
        const zendeskScript = document.createElement('script');
        zendeskScript.id = 'ze-zendesk';
        zendeskScript.type = 'text/javascript';
        zendeskScript.innerHTML = `
          window.zESettings = {
            webWidget: {
              color: { theme: '#cff034' }
            }
          };
        `;
        document.body.appendChild(zendeskScript);
      }
      // livechat插件
      if (res.includes('livechat')) {
        const {
          data: infoRes
        } = await reqContactInfo({
          type: 'livechat',
          channel
        });
        // 动态引入和加载livechat插件
        const scriptEl = document.createElement('script');
        scriptEl.innerHTML = `
      window.__lc = window.__lc || {};
      window.__lc.license = ${infoRes.serviceUrl};
      ; (function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) } var e = { _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () { i(["once", c.call(arguments)]) }, off: function () { i(["off", c.call(arguments)]) }, get: function () { if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) }, call: function () { i(["call", c.call(arguments)]) }, init: function () { var n = t.createElement("script"); n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n) } }; !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e }(window, document, [].slice))

      var chatContainerVisible = false
      var chatWidgetObserver
      var liveChatObserver
      var bodyObserver = new MutationObserver(function(mutations, observer){
        var chatWidgeEl = document.querySelector('#chat-widget')
        if (chatWidgeEl && !chatWidgetObserver) {
          chatWidgetObserver = new MutationObserver(function(mutations, observer){
            var chatWidgeDisplay = chatWidgeEl.style.display
            if (chatWidgeDisplay === 'block') {
              chatContainerVisible = true
              liveChatEl.style.opacity = 1
              liveChatEl.style.visibility = 'visible'
              liveChatEl.style.zIndex = 232425
            } else if (chatWidgeDisplay === 'none') {
              chatContainerVisible = false
            }
          });
          chatWidgetObserver.observe(chatWidgeEl, { attributes:true });
        }

        var liveChatEl = document.querySelector('#chat-widget-container')
        if (liveChatEl && !liveChatObserver) {
          liveChatObserver = new MutationObserver(function(mutations, observer){
            if (!chatContainerVisible && liveChatEl.style.opacity == 1) {
              liveChatEl.style.opacity = 0
              liveChatEl.style.visibility = 'hidden'
              liveChatEl.style.zIndex = -1

              document.getElementById('chat-widget-minimized').display = 'none'
              if (window.contactUsDialog) {
                window.contactUsDialog.livechatVisible = true
              }
            }
          });

          liveChatObserver.observe(liveChatEl, { attributes:true });

          if (chatWidgeEl) {
            bodyObserver = null
          }
        }
      });
      var bodyEl = document.querySelector('body');
      var  options = {
        'childList': true
      };
      bodyObserver.observe(bodyEl, options);
    `;
        document.body.appendChild(scriptEl);
        const noscriptEl = document.createElement('noscript');
        noscriptEl.innerHTML = `
      <a
        href="https://www.livechat.com/chat-with/${infoRes.serviceUrl}/"
        rel="nofollow"
      >Chat with us</a>
      , powered by
      <a
        href="https://www.livechat.com/?welcome"
        rel="noopener nofollow"
        target="_blank"
      >LiveChat</a>
    `;
        document.body.appendChild(noscriptEl);
      }
    };
    const keyDownLongTime = () => {
      //30分钟
      if (Date.now() - appStore.longTime > 30 * 60 * 1000 && appStore.longTime && userInfo.token) {
        appStore.longTime = Date.now();
        LongTimeNoUse({
          props: {
            modelValue: true,
            //展示
            container: t('popup.longTimeNoUse.content'),
            //内容
            leftText: t('popup.longTimeNoUse.leftText'),
            //左边按钮文案
            rightText: t('popup.longTimeNoUse.rightText'),
            //右边按钮文案
            close: unMountComponent,
            // 关闭事件
            leftClickEvent: confirm,
            //左边按钮事件
            rightClickEvent: close //右边按钮事件
          }
        });
        return;
      }
      appStore.longTime = Date.now();
      appStore.toggleWithdraw = true;
    };

    //长时间未操作确认
    const confirm = () => {
      location.reload();
    };

    //长时间未操作取消
    const close = () => {
      unMountComponent();
    };

    // 销毁组件
    const unMountComponent = () => {
      LongTimeNoUse({
        type: 'unmount'
      });
    };

    // 获取客服列表
    const initServer = () => {
      const channel = getChannerl();
      reqServerList({
        channel
      }).then(o => {
        const res = o.data;
        if (res && res.length) {
          // 删掉TelegramChannel的项
          const tgChannelIndex = res.findIndex(item => item === 'TelegramChannel');
          if (tgChannelIndex > -1) {
            res.splice(tgChannelIndex, 1);
            setTelegramChannel(channel);
          }
          appStore.serverList.forEach(item => {
            const str = item.name.toLowerCase();
            if (res.includes(str)) {
              item.show = true;
            }
          });
          // 设置已经获取了客服，避免重复获取
          appStore.setServerList(appStore.serverList);
          // 处理调用不同类型客服
          handleServer(res, channel);
        }
      });
    };
    // 设置首页telegram频道数据
    const setTelegramChannel = async channel => {
      try {
        let {
          data
        } = await reqContactInfo({
          type: 'TelegramChannel',
          channel
        });
        appStore.setTelegramChannelData(_objectSpread({
          show: true
        }, data));
      } catch (error) {
        console.error(error, 445);
      }
    };

    // 初始化一些方法
    const init = async () => {
      // 初始化客服
      if (!appStore.hasServer) {
        initServer();
      }
    };

    /** ***函数 end*****/
    /** ***生命周期start*****/
    onMounted(() => {
      //长时间未使用系统的监听
      window.addEventListener('click', keyDownLongTime, false);
      // 初始化调用
      init();
      appStore.initApp();
      socket.socketReload();
      // 获取首页轮播图
      cacheData.getBanner();
      // 侧边栏
      cacheData.fetchSideBarData();
      // 首页游戏
      cacheData.tabGameListData();
      // 顶部和小喇叭数据
      cacheData.reqTopListInit();
      // 初始化pwa
      usePromptStore().init();
      // 部分项目需要，项目需要如果没有登录需要用户登录，首次进入就需要弹出
      const themeList = ['theme7101', 'theme7102'];
      //themeList.includes(process.env.VUE_APP_PAGE_RHEME) && 
      if (!userInfo.token) {
        setTimeout(() => {
          proxy.$showLogin({
            type: 'login'
          }).catch(() => {});
        }, 1500);
      }
    });
    onUnmounted(() => {
      window.removeEventListener('click', keyDownLongTime);
    });
    watch(() => userInfo.token, () => {
      if (userInfo.token) {
        // 最近玩的
        cacheData.historyInit();
        // 最喜欢的
        cacheData.favoriteInit();
      }
    });
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      appStore,
      cacheData,
      userInfo,
      proxy,
      locale,
      handleServer,
      keyDownLongTime,
      confirm,
      close,
      unMountComponent,
      initServer,
      setTelegramChannel,
      init,
      ptBR,
      esES,
      enUS,
      onMounted,
      onUnmounted,
      watch,
      getCurrentInstance,
      computed,
      reqServerList,
      reqContactInfo,
      socket,
      useAppStore,
      useCacheData,
      useUserInfoStore,
      LongTimeNoUse,
      usePromptStore,
      useI18n,
      getChannerl
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};