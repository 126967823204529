import ShowLogin from '@/componentsFun/Login/index.js'
import useUserInfoStore from '@/store/modules/userInfo'
import rechargePopup from '@/views/rechargeWithdraw/rechargePopup/index.js'
import component from './index.vue'
import AppMountComponent from '@/utils/appMountComponent.js'
const defaultParmas = {
  name: 'RechargePopup',
  component,
  mountBefore: () => {
    const userInfoStore = useUserInfoStore()
    if (!userInfoStore.token) {
      // 注册登录
      ShowLogin({ type: 'login' })
        .then((res) => {
          rechargePopup()
        })
        .catch((err) => {
          console.log(err)
        })
      return false
    }
  },
}
export default (parmas = {}) => {
  parmas = Object.assign(parmas, defaultParmas)
  return new AppMountComponent(parmas)
}
