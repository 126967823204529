import { createVNode as _createVNode, isVNode as _isVNode } from "vue";
import useCopyWriter from '@/store/modules/copywriter';
import useAppStore from '@/store/modules/app';
import { computed } from 'vue';
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export default {
  props: {
    keyWords: {
      type: String,
      require: true,
      default: ''
    },
    // 返回的html标签类型
    tag: {
      type: String,
      default: ''
    }
  },
  setup(props, {
    slot
  }) {
    const {
      keyWords,
      tag
    } = props;
    const copyWriter = useCopyWriter().copyWriterMap;
    const appStore = useAppStore();
    const site = useCopyWriter().site; // yes 多站点  no  普通站点

    const copywritingList = computed(() => {
      return copyWriter === null || copyWriter === void 0 ? void 0 : copyWriter[keyWords].langData[appStore.language];
    });
    return () => {
      var _copywritingList$valu, _copyWriter$keyWords;
      return site === 'yes' ? _createVNode(tag, null, {
        default: () => [(_copywritingList$valu = copywritingList.value) === null || _copywritingList$valu === void 0 ? void 0 : _copywritingList$valu.map(({
          text,
          field,
          type
        }) => type === '1' && _createVNode(tag, {
          "key": field
        }, _isSlot(text) ? text : {
          default: () => [text]
        }))]
      }) : _createVNode(tag, null, {
        default: () => [copyWriter === null || copyWriter === void 0 || (_copyWriter$keyWords = copyWriter[keyWords]) === null || _copyWriter$keyWords === void 0 ? void 0 : _copyWriter$keyWords[appStore.language]]
      });
    };
  }
};