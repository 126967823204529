import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import { getCommonConfig, bindFingerprint, customBindAppId } from '@/api/loginRegister/index.js'
// 加密、
import { getEncryptPwd } from '@/utils/encipher'

const useFingerPrint = () => {
  const createFingerprint = async () => {
    try {
      // 获取指纹公钥，未返回值则代表关闭
      const { data } = await getCommonConfig('finger_print_secret')
      if (!data) return
      const fpPromise = FingerprintJS.load({ apiKey: data })
      fpPromise
        .then((fp) => fp.get())
        .then((result) => {
          bindFingerprint({ bfid: result.visitorId })
        })
    } catch (error) {
      console.log(error)
    }
  }
  // 绑定安卓设备id
  const createCustomBindAppId = async () => {
    try {
      const appId = window?.AndroidEM?.receiveAward()
      await customBindAppId({ appId: getEncryptPwd(appId) })
    } catch (error) {
       console.log(error);
    }
  }
  return { createFingerprint, createCustomBindAppId }
}

export default useFingerPrint
