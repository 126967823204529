import { reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import selectedActivity from '@/hooks/selectedActivity/index';
import router from '@/router';
import { getQueryString } from '@/utils';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    money: {
      type: Number,
      default: 0
    },
    handleOk: {
      type: Function,
      // 成功回调
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/

    const countData = reactive({
      decimals: 2,
      duration: 2000,
      // 金额变化效果控制，1的时候无动画效果，2000时候动画控制2s
      separator: process.env.VUE_APP_CURRENCY_FORMAT === 'comma' ? '.' : ',',
      // 千分位分隔符
      decimal: process.env.VUE_APP_CURRENCY_FORMAT === 'comma' ? ',' : '.' // 小数点分隔符
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    const toDetail = () => {
      const type = getQueryString('type');
      if (type === '19') {
        props.handleOk && props.handleOk();
        return;
      }
      props.handleOk && props.handleOk();
      const list = selectedActivity().handleEventActivity('19');
      router.push({
        path: '/eventsDetail',
        query: {
          id: list[0].id,
          type: list[0].activityType
        }
      });
    };
    /** ***函数 end*****/

    const __returned__ = {
      props,
      countData,
      toDetail,
      reactive,
      CountTo,
      selectedActivity,
      router,
      getQueryString
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};