import { ref } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import addToDesk from './index.js';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup(__props, {
    expose
  }) {
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const show = ref(false);
    expose({
      show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    //关闭
    const close = () => {
      show.value = false;
      unMountComponent();
    };

    /** ***函数 end*****/

    /** ***生命周期start*****/
    // 销毁组件
    const unMountComponent = () => {
      addToDesk({
        type: 'unmount'
      });
    };
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      show,
      close,
      unMountComponent,
      ref,
      MyPopup,
      addToDesk,
      useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};