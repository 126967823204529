import request from '@/utils/request'
/**
 * 获取通用配置
 * @returns
 */
export const getCommonConfig = (key, params) => {
  return request({
    // url: '/kwai/api/commonConfig/group_open',
    url: `/kwai/api/commonConfig/${key}`,
    method: 'get',
    params,
  })
}

/**
 * 获取文案
 * @returns
 */
export const getCopywriterList = (params) => {
  return request({
    url: '/kwai/api/copywriter/list',
    method: 'get',
    params,
  })
}
// 获取站点模式下
export const copywriterGetCopyWriter = (params) => {
  return request({
    url: '/kwai/api/new/copywriter/getCopyWriter',
    method: 'get',
    params,
  })
}

// 读取应用信息
export const skinGetConfig = (params) => {
  return request({
    url: '/kwai/api/skin/getConfig',
    method: 'get',
    params,
  })
}

// 授权
export const syncGoogleInfo = (data) =>
  request({
    url: '/kwai/app/custom/google/syncGoogleInfo',
    method: 'post',
    data,
  })


