import { ref, onMounted } from 'vue';
import MyPopup from '@/components/MyPopup';
import useAppStore from '@/store/modules/app';
import useUserInfoStore from '@/store/modules/userInfo.js';
import copy from 'copy-to-clipboard';
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';
import { freeWithdrawMobileListRecordsApi } from '@/api/FreeWithdraw/index.js';
import { getCommonConfig } from '@/api/loginRegister/index.js';
import { myWindowOpen, isIOS } from '@/utils';
import sharePopup from './index.js';
/** ***引入相关包end*****/

export default {
  setup(__props, {
    expose
  }) {
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const userInfo = useUserInfoStore();
    const appStore = useAppStore();
    const inviteCode = ref(''); // 分享码
    const shareLink = ref(''); // 分享链接
    inviteCode.value = userInfo.userInfo && userInfo.userInfo.inviteCode || '';
    shareLink.value = `${location.origin}?inviteCode=${inviteCode.value}`;
    const isVisible = ref(true);
    const phones = ref([]);
    expose({
      isVisible
    });

    /** ***社交分享 start*****/
    const shareConfigLoading = ref(false);
    const shareLinkList = ref({
      facebook: '',
      whatsapp: '',
      telegram: ''
    });

    // 获取分享配置
    const getShareConfig = async () => {
      shareConfigLoading.value = true;
      try {
        const res = await freeWithdrawMobileListRecordsApi({
          count: 20
        });
        phones.value = res.data;
        let {
          data
        } = await getCommonConfig('deed_share_config');
        data = JSON.parse(data);
        shareLinkList.value.facebook = `https://www.facebook.com/sharer/sharer.php?u=${shareLink.value}`;
        shareLinkList.value.whatsapp = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareLink.value)} ${encodeURIComponent(data.content[appStore.language])}&via=lopscoop`;
        shareLinkList.value.telegram = `https://t.me/share?url=${encodeURIComponent(shareLink.value)}&text=${encodeURIComponent(data.content[appStore.language])}`;
      } catch (err) {
        console.error(err);
      } finally {
        shareConfigLoading.value = false;
      }
    };
    const handleShare = shareName => {
      myWindowOpen(shareLinkList.value[shareName]);
    };
    /** ***社交分享 end*****/

    const handleClose = () => {
      sharePopup({
        type: 'unmount'
      });
    };
    const handleCopy = text => {
      copy(text);
      showToast({
        message: t('mine.success'),
        type: 'success'
      });
    };
    const sendSms = () => {
      const href = `sms:${phones.value.join(',')}${isIOS() ? '&' : '?'}body=Receba 100 BRL de graça,SAQUE RÁPIDO ${shareLink.value}`;
      console.log('🚀 ~ file: index.vue:189 ~ sendSms ~ href:', href);
      myWindowOpen(href);
    };
    onMounted(() => {
      getShareConfig();
    });
    const __returned__ = {
      t,
      userInfo,
      appStore,
      inviteCode,
      shareLink,
      isVisible,
      phones,
      shareConfigLoading,
      shareLinkList,
      getShareConfig,
      handleShare,
      handleClose,
      handleCopy,
      sendSms,
      ref,
      onMounted,
      MyPopup,
      useAppStore,
      useUserInfoStore,
      copy,
      showToast,
      useI18n,
      freeWithdrawMobileListRecordsApi,
      getCommonConfig,
      myWindowOpen,
      isIOS,
      sharePopup
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};