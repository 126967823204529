import { createI18n } from 'vue-i18n'
import { Locale } from 'vant'
import useLocalCache from '@/hooks/storage/localStorage'
// ui框架国际化
// 引入英文语言包
import vanEnUS from 'vant/es/locale/lang/en-US'
// 引入巴西语言包
import vanPtBR from 'vant/es/locale/lang/pt-BR'
// 系统国际化
import ptBR from './pt-BR.json'
import enUS from './en-US.json'
import esES from './es-ES.json'
import esCO from './es-CO.json'
const { setLanguage } = useLocalCache()

const i18n = createI18n({
  warnHtmlMessage: false,
  // 如果本地有语言标识就采用本地，没有就英文
  locale: process.env.VUE_APP_LANGUAGE,
  messages: {
    'pt-BR': ptBR,
    'en-US': enUS,
    'es-ES': esES,
    'es-CO': esCO,
  },
  legacy: false,
})
if (process.env.VUE_APP_LANGUAGE_FRAME === 'pt-BR') {
  Locale.use('pt-BR', vanPtBR)
} else {
  Locale.use('en-US', vanEnUS)
}
// 设置存储国际化，目前只用到了 货币到底是逗号还是分号
setLanguage(process.env.VUE_APP_LANGUAGE)
export default i18n
