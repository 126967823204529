import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-414348d2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "my-image__loading-game"
};
const _hoisted_2 = {
  key: 1,
  class: "my-image__loading-skeleton"
};
const _hoisted_3 = {
  class: "my-image__error-default"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" 当src为''会一直显示加载 "), _createVNode(_component_van_image, _mergeProps({
    class: ['my-image', $setup.loadingShow && 'my-image--loading'],
    fit: $props.fit,
    "lazy-load": $props.lazyLoad
  }, _ctx.$attrs, {
    src: $props.src || ' ',
    onLoad: $setup.handleLoadingComplete,
    onError: $setup.handleLoadingComplete
  }), {
    loading: _withCtx(() => [_renderSlot(_ctx.$slots, "loading", {}, () => [$props.loadingType === 'game' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("i", {
      class: "iconfont icon-jiazai",
      style: _normalizeStyle({
        fontSize: `${$props.iconSize}px`
      })
    }, null, 4 /* STYLE */)])) : (_openBlock(), _createElementBlock("div", _hoisted_2))], true)]),
    error: _withCtx(() => [_renderSlot(_ctx.$slots, "error", {}, () => [_createElementVNode("div", _hoisted_3, [_createElementVNode("i", {
      class: "iconfont icon-tupian",
      style: _normalizeStyle({
        fontSize: `${$props.iconSize}px`
      })
    }, null, 4 /* STYLE */), _createElementVNode("span", null, _toDisplayString(_ctx.$attrs.alt), 1 /* TEXT */)])], true)]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3 /* FORWARDED */
  }, 16 /* FULL_PROPS */, ["class", "fit", "lazy-load", "src"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}