export const userInfo = {
	customId: '', // 用户id
	customName: '', // 用户账号
	nickName: '', // 用户昵称
	vipLevel: '0', // 用户等级
	target: '',
	experience: 0,
	headUrl: '', // 用户头像
	balance: 0,
	payroll: 0,
	canDrawPayroll: 0,
	vipMaxExp: '0',
	vipNum: '0',
	grayUrl: '',
	lightUrl: '',
	titleUrl: '',
	customType: '0', // 用户身份
	inviteCode: '', // 用户邀请码
	totalBet: 0,
	percentage: 0.0,
	channel: '', // 账号注册来源
	bindMobile: '',
	withdrawBindMobileCodeVerifyStatus: false,
	showRegisterPopBox: false,
	registerReward: '',
	existsPwd: true,
	existsWithdrawPwd: true, //是否存在提现密码
	firstDraw: true,
	firstRecharge: true,
	messageUnReadCount: '', // 消息通知条数
	activityConfig: [], // 签到活动、充值理财活动相关信息：是否开启、是否有可领取数据
	bfidClaimed: '', // 指纹id奖励是否已领取,1已领取。主要用于APP
}
