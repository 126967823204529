import { ref, watch, shallowRef, reactive, onMounted } from 'vue';
import Login from './components/Login/index.vue';
import Register from './components/Register/index.vue';
import { getMaxZIndex } from '@/utils/index';
import useAppStore from '@/store/modules/app.js';
import useFreeWithdraw from '@/store/modules/freeWithdraw.js';
import MyTabs from '@/components/MyTabs';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive，等……start*****/

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'login'
    },
    callback: {
      type: Function,
      // 传入移除节点方法,这里是createApp中的方法
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    // 获取store
    const freeWithdraw = useFreeWithdraw();
    const appStore = useAppStore();

    // 弹出框是否显示
    const pageVisible = ref(false);
    // eslint-disable-next-line vue/no-setup-props-destructure
    pageVisible.value = props.visible; // 把props参数赋值给pageVisible
    // tab切换默认是登录  login  register pwd
    const tabType = ref('login');
    // eslint-disable-next-line vue/no-setup-props-destructure

    const activeIndex = ref(0);
    const tabsList = reactive([{
      com: shallowRef(Register),
      title: t('layout.Register'),
      id: '0',
      iconClass: 'icon-zhucetianjiahaoyou'
    }, {
      com: shallowRef(Login),
      title: t('layout.Login'),
      id: '1',
      iconClass: 'icon-denglu'
    }]);

    /** ***ref、reactive，等……end*****/

    /** ***函数 start*****************/
    const beforeChange = val => {
      activeIndex.value = val;
    };
    // tab切换
    const tabTypeFun = type => {
      activeIndex.value = type === 'register' ? 0 : 1;
      tabType.value = type;
    };
    // 关闭弹出框
    const closeLogin = () => {
      pageVisible.value = false;
    };
    const callback = () => {
      if (freeWithdraw.attendStatus === '1' || !freeWithdraw.attendStatus) {
        freeWithdraw.freeWithdrawActivityExecute(true);
      }
      closeLogin();
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(() => {
      tabType.value = props.type;
      activeIndex.value = props.type === 'login' ? 1 : 0;
    });
    // 监听显示的消失，需要移除dom
    watch(() => pageVisible.value, val => {
      !val && props.callback();
    });
    watch(() => props.tabType, val => {
      console.log(val, '-----------');
      if (val === 'login') {
        activeIndex.value = 1;
      }
    });
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      freeWithdraw,
      appStore,
      props,
      pageVisible,
      tabType,
      activeIndex,
      tabsList,
      beforeChange,
      tabTypeFun,
      closeLogin,
      callback,
      ref,
      watch,
      shallowRef,
      reactive,
      onMounted,
      Login,
      Register,
      getMaxZIndex,
      useAppStore,
      useFreeWithdraw,
      MyTabs,
      useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};