/* iframe通信 */
// 向子组件设置用户信息
export const setUserInfo = function setUserInfo(params) {
  window.son.postMessage(
    {
      name: 'setUserInfo',
      params,
    },
    '*'
  )
}
// 向子组件设置国际化语言

export const setLanguage = function setLanguage(params) {
  window.son.postMessage(
    {
      name: 'setLanguage',
      params,
    },
    '*'
  )
}
/**
 * 发送通用数据
 * @param {String} name 标识，用于不同操作
 * @param {Object} params 参数
 */
export const sendData = function sendData(name = 'sendData', params) {
  window.son.postMessage(
    {
      name,
      params,
    },
    '*'
  )
}

// 向子组件传递socket数据
export const deliveSockets = (channel, params) => {
  window?.son?.postMessage(
    {
      name: 'deliveSockets',
      channel,
      params,
    },
    '*'
  )
}

export default {
  setUserInfo,
  setLanguage,
  deliveSockets,
  sendData,
}
