export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    //加载过程中的提示文案
    loadingText: {
      type: String,
      default: ''
    },
    //加载完成后的提示文案
    finishedText: {
      type: String,
      default: ''
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const __returned__ = {};
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};