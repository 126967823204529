// 会话级别存储
import store from 'store2'

export default function useSessionCache() {
  // 促销活动相关当前index
  function setPromotionIndex(res) {
    return store.session('promotionIndex', res)
  }
  // 获取促销活动相关当前index
  function getPromotionIndex() {
    return store.session.get('promotionIndex')
  }
  // 3.删除促销活动相关当前index
  function removePromotionIndex() {
    store.session.remove('promotionIndex')
  }
  // 1.存储邀请码
  function setCacheInviteCode(res) {
    return store.session('inviteCode', res)
  }
  // 2.获取邀请码
  function getCacheInviteCode() {
    return store.session.get('inviteCode')
  }

  // 3.删除邀请码
  function removeCacheInviteCode() {
    store.session.remove('inviteCode')
  }

  // 1.存储搜索游戏数据
  function setSearchRecord(res) {
    return store.session('search', res)
  }
  // 2.获取搜索游戏数据
  function getSearchRecord() {
    return store.session.get('search')
  }

  // 3.删除搜索游戏数据
  function removeSearchRecord() {
    store.session.remove('search')
  }

  //设置首页下载导航栏
  function sethasCloseDownload(res) {
    store.session.set('hasCloseDownload', res)
  }

  //获取首页下载导航栏
  function gethasCloseDownload() {
    return store.session.get('hasCloseDownload')
  }

  //移除首页下载导航栏
  function removeCloseDownload() {
    store.session.remove('hasCloseDownload')
  }
  return {
    setPromotionIndex,
    getPromotionIndex,
    removePromotionIndex,
    getCacheInviteCode,
    setCacheInviteCode,
    removeCacheInviteCode,
    setSearchRecord,
    getSearchRecord,
    removeSearchRecord,
    sethasCloseDownload,
    gethasCloseDownload,
    removeCloseDownload,
  }
}
