import { defineStore } from 'pinia'
import useUserInfoStore from './userInfo.js'
import { deliveSockets } from '@/utils/iframePostMessage.js'
const useSocketStore = defineStore('socket', {
  state: () => ({
    // socket默认订阅事件，与actions下面的方法需一一对应
    defaultTopicList: [
      'first_recharge_channel', // 首次充值成功，会触发余额更新
      'recharge_channel', // 后台管理系统，运营人员手动确认充值，充值成功，会触发余额更新
      'refresh_channel', // 刷新余额，会触发余额更新
      'bet_channel', // 游戏玩家赢了的实时播报
      'mine_channel', // 游戏通道
      'ring_channel', // 游戏通道
      'doubleRing_channel', // 游戏通道
      'dice_channel', // 游戏通道
      'spaceDice_channel', // 游戏通道
      'crash_channel', // 游戏通道
      'connect', // 状态通道
      'disconnect', // 状态通道
      'reconnect_failed', // 状态通道
      'reconnect', // 状态通道
      'message_channel', // 消息数通知
    ],
    bet_channel_list: [], // 游戏玩家赢了的实时播报数据
  }),
  actions: {
    // 首次充值成功，会触发余额更新
    first_recharge_channel(data) {
      console.log('first_recharge_channel', data)
      const userInfoStore = useUserInfoStore()
      if (userInfoStore.token) {
        userInfoStore.updateBalance()
      }
    },
    // 后台管理系统，运营人员手动确认充值，充值成功，会触发余额更新
    recharge_channel(data) {
      console.log('recharge_channel', data)
      if (useUserInfoStore().token) {
        useUserInfoStore().updateBalance()
      }
    },
    // 刷新余额，会触发余额更新
    refresh_channel(data) {
      console.log('refresh_channel', data)
      if (useUserInfoStore().token) {
        useUserInfoStore().updateBalance()
      }
    },
    // 游戏玩家赢了的实时播报
    bet_channel(data) {
      // 首页顶部文字轮播数据列表
      this.bet_channel_list = [data]

      // 给iframe页面传递数据
      deliveSockets('bet_channel', data)
    },
    // 游戏通道
    mine_channel(data) {
      // to do
      deliveSockets('mine_channel', data)
    },
    // 转盘通道
    ring_channel(data) {
      deliveSockets('ring_channel', data)
    },
    // doubleRing通道
    doubleRing_channel(data) {
      deliveSockets('doubleRing_channel', data)
    },
    // 骰子通道
    dice_channel(data) {
      deliveSockets('dice_channel', data)
    },
    // 双骰子通道
    spaceDice_channel(data) {
      deliveSockets('spaceDice_channel', data)
    },
    // 爆点通道
    crash_channel(data) {
      deliveSockets('crash_channel', data)
    },

    // 状态通道
    connect(data) {
      deliveSockets('connect', data)
    },
    // 状态通道
    disconnect(data) {
      deliveSockets('connect', data)
    },
    // 状态通道
    reconnect_failed(data) {
      deliveSockets('connect', data)
    },
    // 状态通道
    reconnect(data) {
      deliveSockets('connect', data)
    },
    // 消息数通知
    message_channel() {
      const userInfoStore = useUserInfoStore()
      userInfoStore.getMessageUnReadCount()
    },
  },
})

export default useSocketStore
