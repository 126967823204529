import request from '@/utils/request'

// 活动详情
export const gameTypeDictApi = (params) => {
  return request({
    url: `/kwai/api/game/getGameTypeList`,
    method: 'get',
    params
  })
}
