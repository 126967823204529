import _objectSpread from "E:/pg/brlwon7-site/node_modules/.pnpm/@babel+runtime@7.24.0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "ant-design-vue/es/message/style";
import _message from "ant-design-vue/es/message";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import useUserInfoStore from '@/store/modules/userInfo';
import { ref, onMounted, reactive, watch } from 'vue';
// 加密、
import { getEncryptPwd } from '@/utils/encipher';
// 接口
import { registerApi, isCheckCodeApi, sendApi } from '@/api/loginRegister/index';
// 获取会话级存储邀请码
import useSessionCache from '@/hooks/storage/sessionStorage';
// 用户信息
import userAgreement from './userAgreement.vue';
// 长期存储
import uselocalStorageCache from '@/hooks/storage/localStorage';
import { handleCPF } from '@/utils/index.js';
import { useI18n } from 'vue-i18n';
/** ***引入相关包end*****/

/** ***ref、reactive，等……start*****/

export default {
  props: {
    callback: {
      type: Function,
      // 成功回调
      default: null
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const {
      getLocationSearch
    } = uselocalStorageCache();
    // 获取邀请码
    const {
      getCacheInviteCode
    } = useSessionCache();
    // 用户信息
    const userInfoStore = useUserInfoStore();
    // props 关闭弹出方法

    // 表单实例
    const formRef = ref();
    // 表单数据
    const formState = reactive({
      customName: '',
      // 注册账号
      password: '',
      // 密码
      password2: '',
      // 第二次密码
      surname: '',
      //真实姓名
      documentId: '',
      //CPF
      code: '',
      // 验证吗
      inviteCode: getCacheInviteCode() || '',
      // 邀请吗
      channel: window.location.hostname.split('login..').slice(-2).join('.') // 来源
    });
    const checkedValue = ref(0); //密码复杂度校验

    // 显示隐藏密码
    const showPassword = ref(true);
    // 是否勾选阅读协议
    const checked = ref(true);
    // 注册请求 加载
    const isLoading = ref(false);
    // 手机区号
    const address = ref(t('systemSetting.areaCode'));

    // 手机注册、邮箱注册、不限制注册方式、通过后台权限判断
    const state = reactive({
      checkCodeInfo: {
        emailFormat: ['gmail'],
        emailCheck: false,
        phoneCheck: false,
        registerType: ''
      }
    });
    // 验证码
    const isNeedCode = ref(false);
    // 验证码文案
    const codeBtnValue = ref('Enviar');
    // 按钮倒计时
    let timer = null;
    // 用户协议
    const agreementShow = ref(false);
    /** ***ref、reactive，等……end*****/

    /** ***函数 start*****/
    // 提交注册
    const handleFinish = async () => {
      if (!checked.value) {
        _message.error(t('logAndReg.register.tips2'));
        return;
      }
      isLoading.value = true;
      try {
        const registerParams = _objectSpread(_objectSpread({}, formState), {}, {
          encryptPwd: getEncryptPwd(formState.password),
          // 如果有快手埋点，需要把参数一起给接口
          kwaiEventParam: getLocationSearch() || ''
        });
        // 密码加密，不需要原来的password字段
        delete registerParams.password;
        delete registerParams.password2;
        await registerApi(registerParams);
        // 调用登录
        const result = await userInfoStore.loginApiFun(formState);
        isLoading.value = false;
        props.callback && props.callback(result);
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    };

    // 发送验证码
    const sendCode = async () => {
      if (!formState.customName) {
        _message.error(t('logAndReg.register.tips1'));
        return;
      }
      codeBtnValue.value = 60;
      timer = window.setInterval(() => {
        codeBtnValue.value = codeBtnValue.value - 1;
        if (codeBtnValue.value === 0) {
          window.clearInterval(timer);
          codeBtnValue.value = 'Enviar';
        }
      }, 1000);
      try {
        await sendApi({
          customName: formState.customName
        });
      } catch (error) {
        window.clearInterval(timer);
        codeBtnValue.value = 'Enviar';
      }
    };

    // 账号输入验证
    const validateCustomName = async (rule, value) => {
      const {
        registerType
      } = state.checkCodeInfo;
      // 没有内容的时候也需要判断手机号还是邮箱还是2种都可以
      if (!value) {
        if (registerType === 'phone') {
          return Promise.reject(t('logAndReg.register.tips3'));
        } else if (registerType === 'email') {
          return Promise.reject(t('logAndReg.register.tips4'));
        } else {
          return Promise.reject(t('logAndReg.register.tips5'));
        }
      } else {
        if (registerType === 'phone') {
          if (value === '') {
            return Promise.reject(t('logAndReg.register.tips6'));
          } else if (value.length < 8 || value.length > 11) {
            return Promise.reject(t('logAndReg.register.tips7'));
          }
        } else if (registerType === 'email') {
          if (value.indexOf('@') === -1) {
            return Promise.reject(t('logAndReg.register.tips4'));
          }
        } else {
          return Promise.resolve();
        }
      }
    };

    // 第一次密码
    const validatePassword = async (rule, value) => {
      if (!value) {
        return Promise.reject(t('logAndReg.login.tips1'));
      } else if (value.length < 8 || value.length > 16) {
        return Promise.reject(t('logAndReg.login.tips2'));
      } else {
        return Promise.resolve();
      }
    };
    // 确认密码
    const validatePassword2 = async (rule, value) => {
      if (!value) {
        return Promise.reject('* ' + t('logAndReg.register.field5'));
      } else if (value.length < 8 || value.length > 16) {
        return Promise.reject(t('logAndReg.login.tips2'));
      } else if (formState.password !== value) {
        return Promise.reject(t('logAndReg.register.tips9'));
      } else {
        return Promise.resolve();
      }
    };
    const validateDocumentId = async (rule, value) => {
      if (!state.checkCodeInfo.cpfSwitch) {
        return Promise.resolve();
      }
      value = value.replace(/(\.)/g, '');
      value = value.replace(/-/g, '');
      if (!value) {
        return Promise.reject('* ' + t('logAndReg.register.tips12'));
      } else if (value.length !== 11 || !/^[0-9]*$/.test(value)) {
        // return Promise.reject(t('logAndReg.register.tips13', [11]))
        return Promise.reject(t('logAndReg.register.tips14'));
      } else {
        return Promise.resolve();
      }
    };
    const validateSurname = async (rule, value) => {
      if (!state.checkCodeInfo.surnameSwitch) {
        return Promise.resolve();
      }
      if (!value) {
        return Promise.reject(t('logAndReg.register.tips11'));
      } else {
        return Promise.resolve();
      }
    };
    const formatterAccount = val => {
      formState.documentId = handleCPF(val);
    };
    const rules = {
      customName: [{
        required: true,
        validator: validateCustomName,
        trigger: 'change'
      }],
      password: [{
        required: true,
        validator: validatePassword,
        trigger: 'change'
      }],
      password2: [{
        required: true,
        validator: validatePassword2,
        trigger: 'change'
      }],
      // 第二次密码
      code: [{
        required: true,
        message: t('logAndReg.register.tips10'),
        trigger: 'change'
      }],
      surname: [{
        validator: validateSurname,
        trigger: 'change'
      }],
      documentId: [{
        required: true,
        validator: validateDocumentId,
        trigger: 'change'
      }]
    };

    // 账号输入框文案后期通过国际化处理
    const checkCodeInforegisterFun = type => {
      if (type === 'phone') {
        return t('logAndReg.register.field1');
      } else if (type === 'email') {
        return t('logAndReg.register.field2');
      } else {
        return t('logAndReg.register.field3');
      }
    };
    // 开启和关闭
    const agreementShowFun = () => {
      agreementShow.value = !agreementShow.value;
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(async () => {
      try {
        var _res$data, _res$data3;
        const res = await isCheckCodeApi({});
        state.checkCodeInfo = res.data;
        if ((res === null || res === void 0 || (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.registerType) === 'phone') {
          var _res$data$phoneCheck, _res$data2;
          isNeedCode.value = (_res$data$phoneCheck = res === null || res === void 0 || (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.phoneCheck) !== null && _res$data$phoneCheck !== void 0 ? _res$data$phoneCheck : false;
        } else if ((res === null || res === void 0 || (_res$data3 = res.data) === null || _res$data3 === void 0 ? void 0 : _res$data3.registerType) === 'email') {
          var _res$data$emailCheck, _res$data4;
          isNeedCode.value = (_res$data$emailCheck = res === null || res === void 0 || (_res$data4 = res.data) === null || _res$data4 === void 0 ? void 0 : _res$data4.emailCheck) !== null && _res$data$emailCheck !== void 0 ? _res$data$emailCheck : false;
        } else {
          var _res$data5, _res$data6;
          isNeedCode.value = (res === null || res === void 0 || (_res$data5 = res.data) === null || _res$data5 === void 0 ? void 0 : _res$data5.phoneCheck) || (res === null || res === void 0 || (_res$data6 = res.data) === null || _res$data6 === void 0 ? void 0 : _res$data6.emailCheck) || false;
        }
      } catch (error) {
        console.log(error);
      }
    });
    watch(() => formState.password, val => {
      if (!val) {
        checkedValue.value = 0;
        return;
      }
      const reg = /(^[0-9]*$|^[A-Za-z]+$)/;
      const reg1 = /^[A-Za-z0-9]+$/;
      const reg2 = /(?=.*[0-9])(?=.*[a-zA-Z])/;
      if (val.match(reg)) {
        checkedValue.value = 1;
      } else if (val.match(reg1)) {
        checkedValue.value = 2;
      } else if (val.match(reg2)) {
        checkedValue.value = 4;
      }
    });
    /** ***生命周期end*****/

    const __returned__ = {
      t,
      getLocationSearch,
      getCacheInviteCode,
      userInfoStore,
      props,
      formRef,
      formState,
      checkedValue,
      showPassword,
      checked,
      isLoading,
      address,
      state,
      isNeedCode,
      codeBtnValue,
      timer,
      agreementShow,
      handleFinish,
      sendCode,
      validateCustomName,
      validatePassword,
      validatePassword2,
      validateDocumentId,
      validateSurname,
      formatterAccount,
      rules,
      checkCodeInforegisterFun,
      agreementShowFun,
      AntMessage: _message,
      useUserInfoStore,
      ref,
      onMounted,
      reactive,
      watch,
      getEncryptPwd,
      registerApi,
      isCheckCodeApi,
      sendApi,
      useSessionCache,
      userAgreement,
      uselocalStorageCache,
      handleCPF,
      useI18n
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};