// main.js (或其他Vue项目的入口文件)
import { message as AntMessage } from 'ant-design-vue'
import { defineComponent, ref, reactive, onMounted, nextTick, watch } from 'vue'
import router from '@/router'
import { CountTo } from 'vue3-count-to'
import logOut from '@/componentsFun/MySecondPopup/index'
import useAppStore from '@/store/modules/app.js'
import useUserInfoStore from '@/store/modules/userInfo'
import copy from 'copy-to-clipboard'
import rechargePopup from '@/views/rechargeWithdraw/rechargePopup/index.js'
import { getVipList, fetchExpSwitch, fetchVipProgress } from '@/api/mine/index.js'
import { customInfoApi } from '@/api/login/login.js'
import { moneyFormat, copyText } from '@/utils/index.js'
import MyImage from '@/components/MyImage'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    /** ***ref、reactive、props，等……start*****/
    const appStore = useAppStore()
    // 用户信息
    const userInfoStore = useUserInfoStore()
    const count = ref(0)

    const { t } = useI18n()

    const defaultImage = require('@/assets/images/mine/personcenter/default-avatar-4.png')

    const IMAGESMAP = {
      0: require('@/assets/images/mine/vip_icon0.png'),
      1: require('@/assets/images/mine/vip_icon1.png'),
      2: require('@/assets/images/mine/vip_icon2.png'),
      3: require('@/assets/images/mine/vip_icon3.png'),
      4: require('@/assets/images/mine/vip_icon4.png'),
      5: require('@/assets/images/mine/vip_icon5.png'),
      6: require('@/assets/images/mine/vip_icon6.png'),
      7: require('@/assets/images/mine/vip_icon7.png'),
      8: require('@/assets/images/mine/vip_icon8.png'),
      9: require('@/assets/images/mine/vip_icon9.png'),
      10: require('@/assets/images/mine/vip_icon10.png'),
      11: require('@/assets/images/mine/vip_icon11.png'),
      12: require('@/assets/images/mine/vip_icon12.png'),
      13: require('@/assets/images/mine/vip_icon13.png'),
      14: require('@/assets/images/mine/vip_icon14.png'),
      15: require('@/assets/images/mine/vip_icon15.png'),
      16: require('@/assets/images/mine/vip_icon16.png'),
      17: require('@/assets/images/mine/vip_icon17.png'),
    }
    const list = [
      [
        {
          iconClass: 'icon-kapianshezhi', //icon类名
          rightIconClass: 'icon-kongxin-right-copy', //右边icon类名
          rightIconColor: 'var(--color-textlv2)', //右边icon颜色
          name: t('mine.WithdrawalManagement'), //文案
          mobileShow: true, //移动端是否展示
          color: 'var(--color-red)', //icon字体颜色
          fn: () => {
            router.push({ path: '/withdraw', query: { current: 'generalCard' } })
          },
        },
      ],
      [
        {
          iconClass: 'icon-Frame-51', //icon类名
          rightIconClass: 'icon-kongxin-right-copy', //右边icon类名
          rightIconColor: 'var(--color-textlv2)', //右边icon颜色
          rightText: t('mine.agnet.description'), //右边文案内容
          name: t('mine.agent'), //文案
          mobileShow: true, //移动端是否展示
          color: 'var(--color-auxiliary)', //icon字体颜色
          fn: () => {
            router.push({ path: '/agent', query: { current: 'myData' } })
          },
        },
      ],
      [
        {
          iconClass: 'icon-Frame-41',
          rightIconClass: 'icon-kongxin-right-copy',
          rightIconColor: 'var(--color-textlv2)',
          name: t('mine.history'),
          mobileShow: true,
          color: 'var(--color-primary)',
          fn: () => {
            router.push({
              name: 'report',
              query: {
                current: 1,
              },
            })
          },
        },
        {
          iconClass: 'icon-bx-notepad',
          rightIconClass: 'icon-kongxin-right-copy',
          rightIconColor: 'var(--color-textlv2)',
          name: t('mine.record'),
          mobileShow: true,
          color: 'var(--color-green)',
          fn: () => {
            router.push({
              name: 'report',
              query: {
                current: 2,
              },
            })
          },
        },
        {
          iconClass: 'icon-Frame-31',
          rightIconClass: 'icon-kongxin-right-copy',
          rightIconColor: 'var(--color-textlv2)',
          name: t('mine.security'),
          mobileShow: true,
          color: 'var(--color-green)',
          fn: () => {
            router.push({
              name: 'security',
            })
          },
        },
        {
          iconClass: 'icon-Group1',
          rightIconClass: 'icon-kongxin-right-copy',
          rightIconColor: 'var(--color-textlv2)',
          name: t('mine.message'),
          hasDot: true, //是否有红点
          dotClass: 'icon-biaoqian',
          dotColor: 'var(--color-red)',
          mobileShow: false,
          color: 'var(--color-white)',
          fn: () => {
            router.push({
              name: 'message',
              query: {
                current: 2,
              },
            })
          },
        },
      ],
      [
        {
          iconClass: 'icon-Frame-21',
          rightIconClass: 'icon-kongxin-right-copy',
          rightIconColor: 'var(--color-textlv2)',
          name: t('mine.faq'),
          mobileShow: true,
          color: 'var(--color-green)',
          fn: () => {
            router.push({
              name: 'message',
              query: {
                current: 4,
              },
            })
          },
        },
        {
          iconClass: 'icon-Frame4',
          rightIconClass: 'icon-kongxin-right-copy',
          rightIconColor: 'var(--color-textlv2)',
          name: t('mine.logout'),
          mobileShow: true,
          color: 'var(--color-red)',
          fn: () => {
            //退出登录
            logOut({
              props: {
                modelValue: true, //展示
                headerText: t('mine.logout.title'), //头部文案
                container: t('mine.logout.content'), //内容
                leftText: t('mine.logout.leftText'), //左边按钮文案
                rightText: t('mine.logout.rightText'), //右边按钮文案
                close: unMountComponent, // 关闭事件
                leftClickEvent: confirmLogOut, //左边按钮事件
                rightClickEvent: closeLogOut, //右边按钮事件
              },
            })
          },
        },
      ],
    ]

    //确认退出登录
    const confirmLogOut = () => {
      useUserInfoStore().removeToken()
      unMountComponent()
      router.push('/home')
    }

    //取消退出登录
    const closeLogOut = () => {
      unMountComponent()
    }

    // 销毁登录组件
    const unMountComponent = () => {
      logOut({ type: 'unmount' })
    }

    //进度条
    const state = reactive({
      progressExp: {},
      progressSwitch: {},
    })
    const userVipLevel = ref(0) // 用户vip 等级

    const sIndex = ref(Number(userInfoStore.userInfo?.vipNum))

    const vipList = ref([])

    const userInfo = ref({})

    //移动端钱包相关列表
    const walletList = [
      {
        iconClass: 'icon-a-Frame1171278169',
        name: t('mine.recharge'),
        color: 'var(--color-white)',
        fn: () => {
          rechargePopup()
        },
      },
      {
        iconClass: 'icon-a-Frame1171278170',
        name: t('mine.withdraw'),
        color: 'var(--color-white)',
        fn: () => {
          if (!userInfoStore.userInfo.existsWithdrawPwd) {
            AntMessage.warn({
              class: 'withdraw__message',
              top: '400px',
              content: t('mine.isCallbackWithdraw'),
              duration: 2,
            })
            router.push({
              name: 'security',
              query: {
                current: 5,
                isCallbackWithdraw: 0,
              },
            })
          } else {
            router.push('/withdraw')
          }
        },
      },
      {
        iconClass: 'icon-a-Frame1171276973',
        name: t('mine.activity'),
        color: 'var(--color-white)',
        fn: () => {
          router.push('/activity')
        },
      },
      {
        hasIcon: true,
        iconClass: 'icon-Frame',
        moreIconClass: 'icon-fanhui', //额外icon
        name: t('mine.agent'),
        color: 'var(--color-white)',
        moreColor: 'var(--color-primary)',
        fn: () => {
          router.push({
            name: 'agent',
            query: {
              current: 'myData',
            },
          })
        },
      },
    ]

    // 响应式数据
    const data = reactive({
      duration: 2000, // 金额变化效果控制，1的时候无动画效果，2000时候动画控制2s
      separator: process.env.VUE_APP_CURRENCY_FORMAT === 'comma' ? '.' : ',', // 千分位分隔符
      decimal: process.env.VUE_APP_CURRENCY_FORMAT === 'comma' ? ',' : '.', // 小数点分隔符
      refreshAnimationRotation: false,
    })

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    const getList = async () => {
      const info = await getVipList()
      vipList.value = info.data || []
    }

    // 经验条宽度
    const progressWidth = (value, key) => {
      const t = value || 0
      const m = (vipList.value[sIndex.value + 1] && vipList.value[sIndex.value + 1][key]) || 0
      let ps = (t / m > 1 ? 1 : t / m) * 100 + '%'
      if (t === 0 && m === 0) {
        ps = '100%'
      }
      return ps
    }

    //上一页
    const handlePrev = () => {
      if (!sIndex.value) return
      sIndex.value--
    }

    //下一页
    const handleNext = () => {
      if (sIndex.value === vipList.value.length - 1) return
      sIndex.value++
    }

    //去消息中心
    const toMessageCenter = () => {
      router.push({
        name: 'message',
        query: {
          current: 2,
        },
      })
    }

    //去客服中心
    const toSupportCenter = () => {
      router.push({
        name: 'message',
        query: {
          current: 1,
        },
      })
    }

    const toVip = () => {
      router.push({
        name: 'activity',
        query: {
          activeIndex: 1,
        },
      })
    }

    //复制内容
    const handleCopy = (text) => {
      copy(text)
      AntMessage.success(t('mine.success'))
    }

    // 刷新余额
    const handleRefresh = async () => {
      data.refreshAnimationRotation = true
      data.duration = 2000
      try {
        const code = await userInfoStore.updateBalance()
        if (code === 200) {
          AntMessage.success({
            content: t('mine.updateBalance'),
            style: {
              zIndex: 1500,
            },
            duration: 2.5,
          })
          setTimeout(() => {
            data.duration = 1
            data.refreshAnimationRotation = false
          }, 2000)
        } else {
          AntMessage.success({
            content: t('mine.updateBalanceFail'),
            style: {
              zIndex: 1500,
            },
            duration: 2.5,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    /**
     * 获取各类型进度条配置
     */
    const fetchProgressConf = async () => {
      try {
        const res = await fetchExpSwitch()
        state.progressSwitch = res.data
      } catch (e) {
        console.log(e)
      }
    }

    /**
     * 获取用户当前等级的各个进度条总经验值
     */
    const fetchVipExp = async () => {
      try {
        const res = await fetchVipProgress()
        state.progressExp = res.data
      } catch (e) {
        console.log(e)
      }
    }

    const getUser = async () => {
      // 获取用户信息
      try {
        const info = await customInfoApi()
        userInfo.value = info.data || {}
      } catch (error) {
        console.log(error)
      }
    }
    /** ***函数 end*****/

    /** ***生命周期start*****/

    onMounted(async () => {
      await getList()
      await fetchProgressConf()
      await fetchVipExp()

      userVipLevel.value = userInfoStore.userInfo.vipNum || userInfoStore.userInfo.vipNum || 0
      sIndex.value = parseInt(userInfoStore.userInfo.vipNum)

      // 获取埋点列表，并上报处理
      // getBuryList()
    })

    watch(
      () => appStore.device,
      (val) => {
        if (val === 'desktop') {
          router.push('/home')
        }
      }
    )

    watch(
      () => userInfoStore.token,
      async (val) => {
        if (val) {
          await nextTick()
          await getUser()
          userVipLevel.value = userInfoStore.userInfo.vipNum || userInfoStore.userInfo.vipNum || 0
          sIndex.value = parseInt(userInfoStore.userInfo.vipNum)
        }
        if (!val) {
          router.push('/home')
        }
      }
    )
    /** ***生命周期end*****/

    return {
      count,
      state,
      appStore,
      userInfoStore,
      vipList,
      list,
      walletList,
      sIndex,
      data,
      defaultImage,
      IMAGESMAP,
      moneyFormat,
      copyText,
      progressWidth,
      handlePrev,
      handleNext,
      toMessageCenter,
      handleCopy,
      handleRefresh,
      toSupportCenter,
      CountTo,
      MyImage,
      toVip,
      t,
    }
  },
})
